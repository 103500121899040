/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* istanbul ignore file */
import * as Sentry from "@sentry/react";
import { addSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import envVariables from "./envVariables";
import { sentryConfig } from "./sentry";

const ignoreErrorCodes = [401, 403, 404, 409, 422];

const ignoreNineAmHealthErrorCodes = new Set([
  10018, // avatar not found
  10003, // Invalid login credentials.
  10002, // wrong verification code
  10017, // address not found
  32068, // no PCP set for user
  10003, // invalid creds
  10005, // invalid data provided (should be reported where the api is called)
  10031, // Email verification required
  10059, // password
  0
]);

export default function reportErrorSentry(
  e: Error | unknown,
  addExtraData: Record<string, unknown> = {}
): void {
  if (!e) return;
  addSentryBreadcrumb("errorHandler", "Captured error", "error", {
    event: e
  });
  let shouldIgnoreErrorCode = false;

  const extra: Record<string, unknown> = {
    ...addExtraData,
    env: envVariables
  };

  // convert to Error if not already
  if (!(e instanceof Error)) {
    if (typeof e === "string") {
      e = new Error(e);
    } else {
      let message = "";
      let httpStatus = 0;
      extra.object = e;

      try {
        const trace = (e as any).traceId as string;
        extra.traceId = trace;
      } catch (_parseError: unknown) {
        // empty
      }

      try {
        const body = (e as any).body.data as object;
        extra.resData = body;
        extra.resDataTxt = JSON.stringify(body);
      } catch (_parseError: unknown) {
        // empty
      }

      let errorMessage = "";
      try {
        errorMessage = (e as any).body.message;
      } catch (_parseError: unknown) {
        // empty
      }

      try {
        if (!errorMessage) errorMessage = (e as any).body.error;
      } catch (_parseError: unknown) {
        // empty
      }

      let errorCode = "";
      try {
        errorCode = (e as any).body.code;
      } catch (_parseError: unknown) {
        // empty
      }

      try {
        if (!errorCode) errorCode = (e as any).body.status;
        httpStatus = parseInt(errorCode, 10);
      } catch (_parseError: unknown) {
        // empty
      }

      // == res object in error ==
      try {
        const res = (e as any).res as Response;
        httpStatus = res.status;
        if (!errorCode) errorCode = res.status.toString();
        const uri = new URL(res.url);
        const path = uri.pathname;
        extra.path = path;
      } catch (_parseError: unknown) {
        // empty
      }

      if (!message) {
        message = `Error: ${errorMessage} | Code: ${errorCode}`;
      }

      if (!message) {
        message = "Unknown error";
      }

      if (ignoreErrorCodes.includes(httpStatus)) {
        shouldIgnoreErrorCode = true;
      }

      if (ignoreNineAmHealthErrorCodes.has(Number(errorCode))) {
        shouldIgnoreErrorCode = true;
      }

      e = new Error(message);
    }
  }

  if (sentryConfig.enabled && !shouldIgnoreErrorCode) {
    Sentry.captureException(e, {
      level: "error",
      extra
    });
  }

  if (!sentryConfig.enabled && !shouldIgnoreErrorCode) {
    console.info(
      "Extra Info for following error was not sent to Sentry:",
      extra
    );

    console.error(e);
  }
}
