export enum Condition {
  diabetesType1 = "Diabetes type 1",
  diabetesType2 = "Diabetes type 2",
  prediabetes = "Prediabetes",
  gestationalDiabetes = "Gestational diabetes",
  unknownDiabetes = "Diabetes - type unknown",
  highBloodPressure = "High blood pressure",
  highColesterol = "High cholesterol",
  kidneyOrRenalDisease = "Kidney or renal disease",
  kidneyDiseaseOrImpairment = "Chronic kidney disease or kidney impairment",
  kidneyDiseaseEndStageOrDialysis = "End-stage kidney or renal disease, or receiving dialysis",
  liverOrHepaticDisease = "Liver or hepatic disease",
  liverFailureOrCirrhosis = "Liver failure or cirrhosis",
  stroke = "Stroke",
  congestiveHeartFailure = "Congestive heart failure (CHF)",
  congestiveHeartFailureClassIIIOrIV = "Congestive heart failure (CHF) Class III or IV",
  coronaryArteryDisease = "Coronary artery disease (CAD)",
  peripheralArterialDisease = "Peripheral arterial disease (PAD)",
  acuteOrChronicMetabolicAcidosis = "Acute or chronic metabolic acidosis",
  obstructiveSleepApnea = "Obstructive Sleep Apnea",
  nonAlcoholicFattyLiverDisease = "Non-alcoholic fatty liver disease (NAFLD or NASH)",
  pcosPolycysticOvarianSyndrome = "Polycystic Ovarian Syndrome (PCOS)",
  gallbladderDisease = "Gallbladder disease (gallstones, inflamed or infected gallbladder)",
  medullaryThyroidCancer = "History of Medullary Thyroid Cancer or Multiple Endocrine Neoplasia syndrome",
  severeDiabeticGastroparesis = "Severe diabetic gastroparesis",
  pancreatitisOrTumors = "History of pancreatitis or tumors of the pancreas",
  obesity = "Obesity (BMI of 30 or greater)",
  organTransplant = "Organ transplant received (i.e. kidney, liver, heart, lung)",
  activeCancer = "Active cancer",
  heartAttackStrokeSurgeryInPast90Days = "Heart attack, stroke, or major surgery within the past 90 days",
  fattyLiverDiseaseNafldNashMasld = "Fatty liver disease (NAFLD, NASH, or MASLD)"
}

export const diabetesConditions = new Set<Condition>([
  Condition.diabetesType1,
  Condition.diabetesType2,
  Condition.severeDiabeticGastroparesis,
  Condition.unknownDiabetes,
  Condition.gestationalDiabetes,
  Condition.severeDiabeticGastroparesis
]);
