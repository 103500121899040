import type { AppliedCouponResponse } from "@9amhealth/openapi";
import { SubscriptionDetailsResponse } from "@9amhealth/openapi";
import type { FC, ReactNode } from "react";
import React, { useEffect, useMemo } from "react";
import { APP_CONTENT_WIDTH } from "src/constants/layout";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import useContentForProgram from "src/lib/useContentForProgram";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import ProgramBloc from "src/state/ProgramBloc/ProgramBloc";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import { SubscriptionInterval } from "src/state/TreatmentPlanCubit/TreatmentPlanCubit";
import { toast, useBloc } from "src/state/state";
import { TranslationKey } from "src/types/translationKey";
import FadeWrap from "src/ui/components/FadeWrap/FadeWrap";
import { Legal } from "src/ui/components/Legal/Legal";
import Loader from "src/ui/components/Loader/Loader";
import Payment from "src/ui/components/Payment/Payment";
import { PaymentContextWithPaymentCubit } from "src/ui/components/PaymentContext/PaymentContext";
import Price from "src/ui/components/Price/Price";
import CustomErrorBoundary from "src/ui/components/SentryBoundary/CustomErrorBoundary";
import SignupCustomBloc from "src/ui/components/SignupCustomContent/state/SignupCustomBloc";
import TextWithKeywords from "src/ui/components/TextWithKeywords/TextWithKeywords";
import { Trust } from "src/ui/components/Trust/Trust";
import {
  CheckoutCartItemKey,
  CheckoutCartItemViewData
} from "../../SignupCustomStepCheckoutDynamicContent/SignupCustomStepCheckoutDynamicContent";
import Translate from "../../Translate/Translate";

export const SignupCustomStepCheckoutRawContent: FC<{
  loading: boolean;
  allLoading?: string[];
  content: SignupCheckoutPageContentProps;
  showShipping: boolean;
  totalPrice: number;
  priceAfterDiscount: number;
  coupon?: AppliedCouponResponse;
  handleOrder?: () => Promise<boolean>;
  labTestsIncluded: boolean;
  setLoading?: (loading: boolean) => void;
  paymentInterval?: SubscriptionInterval;
  subscriptionId?: string;
  cartItems: Record<CheckoutCartItemKey, CheckoutCartItemViewData>;
}> = (props) => {
  const {
    allLoading = [],
    content,
    showShipping,
    coupon,
    totalPrice,
    priceAfterDiscount,
    setLoading,
    cartItems
  } = props;
  const [showInfoSection, setShowInfoSection] = React.useState(true);
  const [cartTotal, setCartTotal] = React.useState(priceAfterDiscount);
  const [cartContent, setCartContent] = React.useState<
    SignupCheckoutPageContentCartSection | undefined
  >(content.cartSection);
  const matchingContent = useContentForProgram();
  const [
    ,
    {
      filterAllSubscriptions,
      buySubscription,
      setDemoFunnel,
      getSubscriptionById
    }
  ] = useBloc(SubscriptionCubit);
  const [, { nextStep, isDemoFunnel }] = useBloc(SignupCustomBloc);
  const [loading, setLoadingState] = React.useState(props.loading);
  const [errorMessage, setErrorMessage] = React.useState<TranslationKey>();
  const showPayment = totalPrice > 0;

  setDemoFunnel(isDemoFunnel);

  const setAllLoading = (set: boolean) => {
    setLoadingState(set);
    setLoading?.(set);
  };

  const handlePaymentStart = () => {
    setErrorMessage(undefined);
    setAllLoading(true);
  };

  const funnelSpecificSubscription = useMemo(() => {
    if (props.subscriptionId) {
      return getSubscriptionById(props.subscriptionId);
    }
    return filterAllSubscriptions({
      status: [SubscriptionDetailsResponse.status.DRAFT],
      duration: [SubscriptionInterval.monthly, SubscriptionInterval.quarterly],
      looseCheck: true
    })[0] as SubscriptionDetailsResponse | undefined;
  }, []);

  useEffect(() => {
    if (!funnelSpecificSubscription) {
      reportErrorSentry(
        new Error("Checkout could not find subscription to buy")
      );
      toast.error("error.generic");
    }
  }, [funnelSpecificSubscription]);

  const handleOrder = React.useCallback(() => {
    setAllLoading(true);
    setErrorMessage(undefined);

    if (props.handleOrder) {
      void props.handleOrder().catch((err: unknown) => {
        reportErrorSentry(err);
        setAllLoading(false);
      });
      return true;
    }

    if (funnelSpecificSubscription) {
      setAllLoading(true);
      void buySubscription(funnelSpecificSubscription.id, {
        onSuccess: () => {
          setAllLoading(false);
          void nextStep();
        },
        onError: () => {
          setAllLoading(false);
          reportErrorSentry(new Error("Error buying subscription"));
          toast.error("error_generic");
          setErrorMessage("error_generic");
        }
      });
    } else {
      window.location.reload();
      return false;
    }

    return true;
  }, [props.handleOrder]);

  const handleEnrollClick = React.useCallback(() => {
    // setShowPayment(true);
    setShowInfoSection(false);
    setCartTotal(funnelSpecificSubscription?.totalPrice ?? 0);

    if (matchingContent.slug) {
      void ProgramBloc.loadProgramMediaContent(matchingContent.slug).then(
        (e) => {
          let image = e?.image?.url;
          if (image) {
            image += `?w=200&h=200&fit=crop&auto=format`;
          }

          setCartContent({
            title: e?.title ?? "",
            subtitle: e?.description ?? "",
            image
          });
        }
      );
    }
  }, [
    setCartContent,
    setShowInfoSection,
    matchingContent,
    funnelSpecificSubscription
  ]);

  return (
    <Loader
      active={loading || allLoading.includes(LoadingKey.treatmentPlan)}
      fixed
    >
      <FadeWrap>
        <nine-center
          style={{
            maxWidth: `${APP_CONTENT_WIDTH}px`,
            margin: "0 auto"
          }}
        >
          <nine-spacer s="xl"></nine-spacer>
          <nine-heading>
            <h3>{content.title}</h3>
            <nine-spacer s="xs"></nine-spacer>
            <p className="m0 color-c-80">{content.subtitle}</p>
          </nine-heading>

          {showInfoSection &&
            content.infoSections?.({ onEnroll: handleEnrollClick })}

          {cartContent && (
            <nine-info-section style={{ margin: "0.8em 0" }}>
              <p
                slot="title"
                className="m0 strong as-tiny"
                style={{ color: "var(--color-charcoal-60)", marginTop: "3rem" }}
              >
                <Translate msg="summary" uppercase={true} />
              </p>
              <nine-info-container slot="content">
                <nine-info-row
                  variant="medication"
                  imageSrc={cartContent.image}
                >
                  <h5 slot="title" className="m0 strong">
                    {content.programTitle}
                  </h5>

                  <p slot="description" className="m0 color-c-80">
                    <TextWithKeywords text={cartContent.subtitle} />
                  </p>
                </nine-info-row>

                <nine-table shadow="false" showTitle="false" gradient="false">
                  {showShipping && (
                    <nine-row px0 pyLarge borderGrayLight>
                      <p slot="title" className="m0 strong">
                        <Translate msg="shipping" />
                      </p>
                      <p slot="label" className="m0 text-right">
                        <Price amount={0} />
                      </p>
                    </nine-row>
                  )}
                  {coupon?.amountOff && (
                    <nine-row px0 pyLarge borderGrayLight>
                      <p slot="title" className="m0 strong">
                        {translate(`discount_name`, {
                          context: coupon.coupon
                        })}
                      </p>
                      <p slot="label" className="m0 text-right">
                        -<Price amount={coupon.amountOff} />
                      </p>
                    </nine-row>
                  )}
                  {(cartItems.onboarding.show ||
                    cartItems.ongoingCare.show) && (
                    <nine-row px0 pyLarge borderGrayLight>
                      {cartItems.onboarding.show && (
                        <>
                          <p slot="title" className="as-little color-c">
                            {cartItems.onboarding.title && (
                              <Translate msg={cartItems.onboarding.title} />
                            )}
                          </p>
                          <div slot="label" style={{ marginBottom: "1rem" }}>
                            <p className="as-little color-c text-right mt0">
                              <Price
                                amount={cartItems.onboarding.fee}
                                formatLocale
                                keepNumber
                              />
                            </p>
                          </div>
                        </>
                      )}

                      {cartItems.ongoingCare.show && (
                        <>
                          <p slot="title" className="m0 as-little color-c">
                            {cartItems.ongoingCare.title && (
                              <Translate msg={cartItems.ongoingCare.title} />
                            )}
                          </p>
                          <div slot="label" className="m0">
                            <p className="m0 as-little color-c text-right">
                              <Price
                                amount={cartItems.ongoingCare.fee}
                                duration={props.paymentInterval}
                                formatLocale
                                keepNumber
                              />
                            </p>
                          </div>
                        </>
                      )}
                    </nine-row>
                  )}
                  <nine-row px0 pyLarge borderGrayLight>
                    <h5 slot="title" className="m0 strong color-c">
                      <Translate msg="total" />
                    </h5>
                    <h5 slot="label" className="m0 strong color-c text-right">
                      <Price amount={cartTotal} formatLocale keepNumber />
                    </h5>
                  </nine-row>
                </nine-table>
              </nine-info-container>
            </nine-info-section>
          )}

          {showPayment && (
            <PaymentContextWithPaymentCubit>
              <CustomErrorBoundary>
                <Payment
                  buyAction={async () => handleOrder()}
                  onPaymentStart={() => handlePaymentStart()}
                  onError={() => setAllLoading(false)}
                  errorMessage={errorMessage}
                  onComplete={() => setAllLoading(false)}
                  subscriptionId={"subscription.id"}
                  legal={
                    <Legal
                      duration={SubscriptionInterval.monthly}
                      cartItems={cartItems}
                      additionalText={content.legalAppendix}
                    />
                  }
                />
              </CustomErrorBoundary>
            </PaymentContextWithPaymentCubit>
          )}

          {content.showMoneyBackGuarantee && (
            <nine-additional-benefits style={{ marginTop: 0 }}>
              <nine-benefit
                icon="moneyBack"
                content="30-day money back guarantee"
              ></nine-benefit>
            </nine-additional-benefits>
          )}
          <Trust />
        </nine-center>
      </FadeWrap>
    </Loader>
  );
};

export interface InfoSectionProps {
  onEnroll?: () => unknown;
}

export interface SignupCheckoutPageContentCartSection {
  title: ReactNode;
  image?: string;
  subtitle?: string;
  showShipping?: boolean;
  couponCode?: string;
  couponText?: string;
}

export interface SignupCheckoutPageContentProps {
  title: string;
  subtitle?: string;
  infoSections?: (props: InfoSectionProps) => ReactNode;
  cartSection?: SignupCheckoutPageContentCartSection;
  legalAppendix?: ReactNode;
  showMoneyBackGuarantee?: boolean;
  programTitle?: string;
}
