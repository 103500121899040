import React, { useEffect } from "react";
import { useTextField } from "react-aria";
import {
  TextAreaInputEl,
  TextAreaInputErrorEl,
  TextAreaInputLabelEl,
  TextareaProps,
  TextAreaInputTextEl,
  TextAreaContainer,
  TextAreaWrap
} from "atom/textarea/textareaComponents";
import { FormFieldBaseState } from "atom/autoform/AutoFormBloc";

function TextAreaNoRef(
  props: TextareaProps,
  ref?: React.Ref<FormFieldBaseState | null>
) {
  const [value, setValue] = React.useState(props.defaultValue ?? "");
  const [isFocused, setIsFocused] = React.useState(false);
  let {
    label,
    maxRows = 10,
    description,
    large,
    errorParser = ({ error }) => {
      if (!error) return "";
      if (typeof error === "string") return error;
      return error.message;
    }
  } = props;

  let elRef = React.useRef<HTMLTextAreaElement>(null);

  let { labelProps, inputProps } = useTextField(
    {
      ...props,
      inputElementType: "textarea"
    },
    elRef
  );

  React.useImperativeHandle(
    ref,
    () => ({
      setValue: (value: string) => {
        setValue(value);
      }
    }),
    [elRef]
  );

  useEffect(() => {
    // TODO: fire change envet to trigger validation
    elRef.current?.setAttribute("data-value", value);
    elRef.current?.setAttribute(
      "rows",
      Math.min(value.split("\n").length, maxRows).toString()
    );
  }, [value]);

  const errorString = errorParser(props);

  return (
    <TextAreaContainer data-disabled={inputProps.disabled}>
      <TextAreaWrap data-invalid={props.isInvalid}>
        <TextAreaInputEl
          {...inputProps}
          data-large={large}
          value={value}
          rows={1}
          ref={elRef}
          onChange={(e) => {
            setValue(e.currentTarget.value);
            e.target.setAttribute("data-value", e.currentTarget.value);
            inputProps.onChange?.(e);
          }}
          data-focused={isFocused ? "true" : "false"}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />

        {label && (
          <TextAreaInputLabelEl
            {...labelProps}
            className="body1"
            data-large={large}
          >
            {label}
            {props.isRequired && label ? "*" : ""}
          </TextAreaInputLabelEl>
        )}
      </TextAreaWrap>
      {description && (
        <TextAreaInputTextEl slot="description" className="little1">
          {description}
        </TextAreaInputTextEl>
      )}
      {errorString && (
        <TextAreaInputErrorEl className="little1 data-field-error">
          {errorString}
        </TextAreaInputErrorEl>
      )}
    </TextAreaContainer>
  );
}

const TextArea = React.forwardRef(TextAreaNoRef) as typeof TextAreaNoRef;

export default TextArea;
