import type {
  GetLabOrdersResponseItem,
  UserLifelineItemResponseObject
} from "@9amhealth/openapi";
import {
  LabOrderControllerService,
  LabValuesControllerService,
  UserLifelineItemControllerService
} from "@9amhealth/openapi";
import { Cubit } from "blac";
import type { Device, Observation, Reference } from "fhir/r4";
import { LoincCodingCode } from "src/constants/fhir";
import { globalEvents } from "src/constants/globalEvents";
import type { OrderedLabSkus } from "src/constants/labSku";
import { DateFormats, dateLocal } from "src/lib/date";
import type { CustomObservation, StructuredObservation } from "src/lib/fhir";
import Fhir from "src/lib/fhir";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { fileState, loadingState } from "src/state/state";
import { BloodGlucoseTag } from "src/ui/components/BloodGlucoseForm/BloodGlucoseTagSelector";
import type { FileItem } from "../FilesCubit/FilesCubit";
import { LoadingKey } from "../LoadingCubit/LoadingCubit";

enum SourceType {
  CONNECTIVE_HEALTH = "https://connectivehealth.io"
}

interface LabResultsState {
  uploads: FileItem[];
  observations?: CustomObservation[];
  labOrders: GetLabOrdersResponseItem[];
}

const lifelineObservationResponseCache = new Map<string, CustomObservation>();

export default class LabResultsCubit extends Cubit<LabResultsState> {
  constructor() {
    super({
      uploads: [],
      observations: undefined,
      labOrders: []
    });

    window.addEventListener(globalEvents.USER_CLEAR, () => {
      this.emit({
        uploads: [],
        observations: undefined,
        labOrders: []
      });
    });
  }

  public readonly isObservationLabResult = (
    observation: CustomObservation
  ): boolean => {
    const labResultSourceTypes = [/diagnostics/, /bioreference/, /api/];
    return labResultSourceTypes.some((labResultSourceType) =>
      observation.sourceType?.match(labResultSourceType)
    );
  };

  public readonly isObservationUserReported = (
    observation: CustomObservation
  ): boolean => {
    const userReportedSourceTypes = [/internal-questionnaire/];
    return userReportedSourceTypes.some((userReportedSourceType) =>
      observation.sourceType?.match(userReportedSourceType)
    );
  };

  /**
   * Takes all the lab values and sorts them into a structured object
   */
  public readonly getStructuredObservations = (): Map<
    string,
    StructuredObservation
  > => {
    const observations = this.state.observations ?? [];
    return Fhir.createStructuredObservations(observations);
  };

  public readonly getStructuredObservationsArray =
    (): StructuredObservation[] => {
      const list = Array.from(this.getStructuredObservations().values());
      const sortedByName = list.sort((a, b) => a.title.localeCompare(b.title));
      return sortedByName;
    };

  public readonly loadUserUploads = async (): Promise<void> => {
    loadingState.start(LoadingKey.userUploads);
    try {
      const data = await UserLifelineItemControllerService.getLifelineItems([
        "labs.results_photo"
      ]);

      const uploads: FileItem[] = [];
      for (const item of data.data as {
        deserializedPayload: { fileId: string };
      }[]) {
        const fileItem = await fileState.loadFile({
          id: item.deserializedPayload.fileId
        });
        if (fileItem) {
          uploads.push(fileItem);
        }
      }

      this.emit({ ...this.state, uploads });
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
    loadingState.finish(LoadingKey.userUploads);
  };

  public readonly loadUserShipments = async (): Promise<void> => {
    loadingState.start(LoadingKey.labOrders);
    try {
      const {
        data: { labOrders }
      } = await LabOrderControllerService.allLabOrders();

      this.emit({ ...this.state, labOrders });
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
    loadingState.finish(LoadingKey.labOrders);
  };

  public readonly loadObservations = async (): Promise<void> => {
    loadingState.start(LoadingKey.loadingLabResults);
    try {
      const { data } = await UserLifelineItemControllerService.getLifelineItems(
        ["hl7_fhir_r4_lab_value"]
      );

      const allObservations: CustomObservation[] = data.map(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        (userLifelineItem): CustomObservation => ({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          ...userLifelineItem.deserializedPayload.observation,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
          sourceType: userLifelineItem.deserializedPayload?.source?.type ?? "",
          id: userLifelineItem.id
        })
      );

      // Filter out connective health observations
      const filteredObservations = allObservations.filter(
        (observation) => observation.sourceType !== SourceType.CONNECTIVE_HEALTH
      );

      this.emit({
        ...this.state,
        observations: filteredObservations
      });
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
    loadingState.finish(LoadingKey.loadingLabResults);
  };

  public readonly uploadLab = async (file: File): Promise<void> => {
    loadingState.start(LoadingKey.upload);
    try {
      const upload = await fileState.uploadFile({
        file,
        fileAttributes: {
          "labs.user_result": true
        }
      });

      if (upload) {
        await UserLifelineItemControllerService.addLifelineItem(
          "labs.results_photo",
          {
            fileId: upload.id,
            fileCreateDate: dateLocal().format(DateFormats.ISO_FULL)
          } as unknown as never
        );
        this.emit({ ...this.state, uploads: [upload, ...this.state.uploads] });
      }
    } catch (e: unknown) {
      reportErrorSentry(e);
    }

    loadingState.finish(LoadingKey.upload);
  };

  public readonly removeHiddenObservations = (
    ovs: CustomObservation[]
  ): CustomObservation[] => {
    const hidden: LoincCodingCode[] = [
      LoincCodingCode.bmi,
      LoincCodingCode.height,
      LoincCodingCode.weight
    ];
    return ovs.filter((o) => {
      const code = o.code.coding?.[0].code;
      return !hidden.includes(code as LoincCodingCode);
    });
  };

  public readonly getLabTestOrders = (
    sku: OrderedLabSkus,
    statuses: GetLabOrdersResponseItem.status[] = [],
    allowed = true
  ): GetLabOrdersResponseItem[] =>
    this.state.labOrders.filter(
      (labOrder) =>
        labOrder.orderedLabTests?.includes(sku as string) &&
        (statuses.length
          ? allowed
            ? statuses.includes(labOrder.status)
            : !statuses.includes(labOrder.status)
          : true)
    );

  static storeConnectedDeviceLabValues = async (
    observations: Observation[],
    /**
     * The source type of the lab values,
     * [enum](service/src/main/java/com/nineamhealth/backend/observation/domain/ObservationSource.java)
     *
     * "apple-health" is for data from the Apple Health app
     **/
    sourceType: "apple-health"
  ): Promise<string> => {
    let errorResponse = "";
    try {
      await LabValuesControllerService.storeDeviceLabValues({
        observations,
        source: {
          type: sourceType
        }
      });
    } catch (error) {
      reportErrorSentry(error);
      errorResponse = "Error submitting lab results";
    }
    return errorResponse;
  };

  static storeSelfReportedLabValues = async (
    observations: Observation[],
    /**
     * The source type of the lab values,
     * [enum](service/src/main/java/com/nineamhealth/backend/observation/domain/ObservationSource.java)
     *
     * "messenger" will show the lab values in the messenger
     * "internal-demo" is used for testing purposes
     **/
    sourceType: "messenger" | "internal-demo"
  ): Promise<string> => {
    let errorResponse = "";
    loadingState.start(LoadingKey.submitLabResults);
    try {
      await LabValuesControllerService.storeSelfReportedLabValues({
        observations,
        source: {
          type: sourceType
        }
      });
    } catch (error) {
      reportErrorSentry(error);
      errorResponse = "Error submitting lab results";
    }
    loadingState.finish(LoadingKey.submitLabResults);
    return errorResponse;
  };

  static createObservationBloodPressure = (
    value: string | { systolic: number; diastolic: number },
    date: string
  ): Observation => {
    let systolic: number;
    let diastolic: number;

    if (typeof value === "string") {
      // parse value if passed as string like "120/80"
      const [s, d] = value.split("/");
      systolic = parseInt(s, 10);
      diastolic = parseInt(d, 10);
    } else {
      // get values from object
      // eslint-disable-next-line @typescript-eslint/prefer-destructuring
      systolic = value.systolic;
      // eslint-disable-next-line @typescript-eslint/prefer-destructuring
      diastolic = value.diastolic;
    }

    // validate values
    if (isNaN(systolic) || isNaN(diastolic)) {
      throw new Error("Invalid blood pressure value");
    }
    // validate range
    if (systolic < 0 || systolic > 300 || diastolic < 0 || diastolic > 300) {
      throw new Error("Invalid blood pressure value");
    }

    return Fhir.createObservation({
      code: LoincCodingCode.bloodPressure,
      date,
      component: [
        Fhir.createObservationComponent(LoincCodingCode.bloodPressureSystolic, {
          value: systolic
        }),
        Fhir.createObservationComponent(
          LoincCodingCode.bloodPressureDiastolic,
          {
            value: diastolic
          }
        )
      ]
    });
  };

  static createObservationBloodGlucose = (
    value: string,
    date: string,
    selectedTag?: BloodGlucoseTag
  ): Observation => {
    // parse value which is passed as string like "130.123"
    const numberValue = Number(value);

    // validate value
    if (isNaN(numberValue) || numberValue <= 0) {
      throw new Error("Invalid blood glucose value");
    }

    return Fhir.createObservation({
      code:
        selectedTag === BloodGlucoseTag.Fasting
          ? LoincCodingCode.bloodGlucoseCapillaryFasting
          : LoincCodingCode.bloodGlucoseCapillary,
      temporalPeriod:
        selectedTag !== BloodGlucoseTag.Fasting ? selectedTag : undefined,
      date,
      valueQuantity: Fhir.createValueQuantity(
        numberValue,
        selectedTag === BloodGlucoseTag.Fasting
          ? LoincCodingCode.bloodGlucoseCapillaryFasting
          : LoincCodingCode.bloodGlucoseCapillary
      )
    });
  };

  static createObservationDailySteps = (
    value: string | number,
    meta: {
      date: string;
      device?: Reference;
      contained?: Device[];
    }
  ): Observation => {
    const { date, device } = meta;
    // parse value which is passed as string like "130.123"
    const numberValue = Number(value);

    // validate value
    if (isNaN(numberValue) || numberValue <= 0) {
      throw new Error("Invalid weight value");
    }

    return Fhir.createObservation({
      code: LoincCodingCode.stepsInDay,
      status: "final",
      date: date.split("T")[0],
      category: [Fhir.categories.activity],
      valueQuantity: Fhir.createValueQuantity(
        numberValue,
        LoincCodingCode.stepsInDay
      ),
      device,
      contained: meta.contained
    });
  };

  static createObservationWeight = (
    value: string,
    date: string
  ): Observation => {
    // parse value which is passed as string like "130.123"
    const numberValue = Number(value);

    // validate value
    if (isNaN(numberValue) || numberValue <= 0) {
      throw new Error("Invalid weight value");
    }

    return Fhir.createObservation({
      code: LoincCodingCode.weight,
      date,
      valueQuantity: Fhir.createValueQuantity(
        numberValue,
        LoincCodingCode.weight
      )
    });
  };

  static createObservationWaistCircumference = (
    value: string,
    date: string
  ): Observation => {
    // parse value which is passed as string like "130.123"
    const numberValue = Number(value);

    // validate value
    if (isNaN(numberValue) || numberValue <= 0) {
      throw new Error("Invalid waist circumference value");
    }

    return Fhir.createObservation({
      code: LoincCodingCode.waistCircumference,
      date,
      valueQuantity: Fhir.createValueQuantity(
        numberValue,
        LoincCodingCode.waistCircumference
      )
    });
  };

  public static getLifelineItemById = async (
    id: string
  ): Promise<UserLifelineItemResponseObject> => {
    const { data } =
      await UserLifelineItemControllerService.getLifelineItem(id);
    return data;
  };

  public static getObservationFromLifelineItemById = async (
    id: string
  ): Promise<CustomObservation | undefined> => {
    if (lifelineObservationResponseCache.has(id)) {
      return lifelineObservationResponseCache.get(id);
    }

    let observation = undefined;
    try {
      const data = await LabResultsCubit.getLifelineItemById(id);
      if (data.itemType === "hl7_fhir_r4_lab_value") {
        observation =
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          data.deserializedPayload?.observation as unknown as CustomObservation;

        lifelineObservationResponseCache.set(id, observation);
      }
    } catch (error) {
      reportErrorSentry(error);
    }

    return observation;
  };
}
