import Language from "src/constants/language";
import type { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import { JourneyName } from "./getEducationalContent";

const sanityQuery = {
  getBySlug: (slug: string): string => {
    return `*[slug.current == '${slug}']`;
  },

  // Get Care Team Members by internal Sanity id field
  getCareTeamMembersByIds: (refs: string): string => {
    return `*[_type=='careTeam' && _id in [${refs}]]`;
  },

  // Get Care Team Members by external backend user id field
  getCareTeamMembersByUserIds: (refs: string): string => {
    return `*[_type=='careTeam' && userId in [${refs}]]`;
  },

  getAppContentBySlug: (slug: string): string => {
    return `*[slug.current=='${slug}' && _type=="appContent"] {
      ...,
      "image": image.asset->,
}`;
  },

  getById: (id: string): string => {
    return `*[_id == "${id}"] {..., "image": image.asset->,"sanctuaryHealthVideo": sanctuaryHealthVideo->}`;
  },

  getByIds: (ids: string): string => {
    return `*[_id in [${ids}]] {...,  "categories": categories[]->, "image": image.asset->,"sanctuaryHealthVideo": sanctuaryHealthVideo->}`;
  },
  programContentBySlug: (slug: string): string => {
    return `*[slug.current=='${slug}' && _type=="programContent"] {
      ...,
      "image": image.asset->,
      "moduleItems": moduleItems[]{
          ...,
          "image": image.asset->,
      },
      "attachments": attachments[]{...,"file": file.asset->},
}`;
  },

  educationalContentByProgram: (
    program: KnownProgram | "no-program",
    language = Language.en
  ): string => {
    return `*[_type=="educationalContent" && language == "${language}" && "${program}" in linkedPrograms[]->slug.current] {
      ...,
      _id,
      title,
      description,
      url,
      "image": image.asset->,
      "sanctuaryHealthVideo": sanctuaryHealthVideo->,}`;
  },

  educationalContentByCategories: (
    categoriesIds: string[],
    language = Language.en
  ) => {
    const categoriesIdsFormatted = categoriesIds
      .map((item) => `"${item}"`)
      .join(", ");
    return `*[_type=='educationalContent' && language == "${language}" && count((categories[]._ref)[@ in [${categoriesIdsFormatted}]]) > 0] {
      ...,
      _id,
      title,
      "categories": categories[]->,
      description,
      url,
      "image": image.asset->,
      "sanctuaryHealthVideo": sanctuaryHealthVideo->,
    }`;
  },

  educationalUserJourney: (
    journeyName = JourneyName.default,
    language = Language.en
  ): string => {
    return `*[_type=='journeyContent' && language == "${language}" && name == "${journeyName}"] {
      interval,
      language,
      "listOfClasses": listOfClasses[]->,
      name,
      type
    }`;
  },

  EducationalContentClassItemsByCategory: (
    language = Language.en,
    categoriesIds: string[],
    educationalContentRefs: string[]
  ) => {
    const categoriesIdsFormatted = categoriesIds
      .map((item) => `"${item}"`)
      .join(", ");
    const educationalContentRefsFormatted = educationalContentRefs
      .map((item) => `"${item}"`)
      .join(", ");
    return `*[_type=='educationalContent' && language == "${language}"&& count((categories[]._ref)[@ in [${categoriesIdsFormatted}]]) > 0 && _id in [${educationalContentRefsFormatted}]] {
      ...,
      _id,
      title,
      "categories": categories[]->,
      description,
      url,
      "image": image.asset->,
      "sanctuaryHealthVideo": sanctuaryHealthVideo->,
    }`;
  },

  featureFlags: () => {
    return `*[_id == "featureflags"]`;
  },

  getEstablishCareStepSettings: () => {
    return `*[_id == "establishcarestepsettings"] {
      ...,
      "careTeamMembers": careTeamMembers[]-> {
        ...,
        "image": image.asset->
      }
    }`;
  }
};

export default sanityQuery;
