import DOMPurify from "dompurify";
import type { DOMNode, HTMLReactParserOptions } from "html-react-parser";
import parse, { domToReact } from "html-react-parser";
import type { ReactElement } from "react";
import React from "react";
import showdown from "showdown";
import VimeoService from "src/api/VimeoService";
import { toast } from "src/state/state";
import ExampleInsuranceCard from "src/ui/components/ExampleInsuranceCard/ExampleInsuranceCard";
import {
  AppPopup,
  AppQueryPopupsController
} from "src/ui/components/AppQueryPopups/AppQueryPopupsBloc";
import Feedback from "src/ui/components/Feedback/Feedback";
import Link from "src/ui/components/Link/Link";
import MediaPlayer from "src/ui/components/MediaPlayer/MediaPlayer";
import Underline from "src/ui/components/Underline/Underline";
import { Document, getLegalDocumentLink } from "./getLegalDocumentLink";
import reportErrorSentry from "./reportErrorSentry";

export enum NineamComponents {
  video = "9am:video",
  feedback = "9am:feedback"
}

const htmlSettings = () => ({
  ALLOWED_TAGS: [
    "b",
    "p",
    "em",
    "strong",
    "div",
    "span",
    "a",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "ul",
    "ol",
    "li",
    "br",
    "hr",
    "i",
    "u",
    "sup"
  ],
  ALLOWED_ATTR: ["href", "type", "src", "version", "data-embed"]
});

interface ElData {
  name?: string;
  data?: string;
  attribs: {
    href?: string;
    type?: NineamComponents | string;
    src?: string;
    version?: string | "default";
    "data-embed"?: string;
  };
  children: DOMNode[];
}

const markdownConverter = new showdown.Converter({
  noHeaderId: true,
  simpleLineBreaks: true,
  requireSpaceBeforeHeadingText: true,
  underline: true
});

interface HtmlParserOptions {
  convertMarkdownToHtml?: boolean;
  emAsUnderline?: boolean;
  disabledTags?: string[];
  removeDashOnlyRows?: boolean;
}

class HtmlParser {
  content: string;
  options: HtmlParserOptions = {};
  htmlSettings = htmlSettings();

  constructor(dirty: string, options: HtmlParserOptions = {}) {
    let text = dirty;
    this.options = options;

    if (options.convertMarkdownToHtml) {
      // replace() needed to properly parse * and _ for bold, italic and underlined text (Typeform returns \* and \_ for every * and _)
      text = text.replace(/\\\*/g, "*").replace(/\\_/g, "_");

      if (options.removeDashOnlyRows) {
        const textRows = text.split("\n");
        // filter out rows that contain only "-" or "- " (just a dash, or a dash with only whitespaces afterwards)
        const filteredTextRows = textRows.filter(
          (row) => !new RegExp(/^-[\s+]?$/).test(row)
        );
        text = filteredTextRows.join("\n");
      }

      text = markdownConverter.makeHtml(text);
    } else {
      text = text.replace(/\n/g, "<br />");
      text = text.replace(/_([^_]*)_/g, "<u>$1</u>");
    }

    // remove disabledTags from ALLOWED_TAGS
    if (options.disabledTags) {
      this.htmlSettings.ALLOWED_TAGS = this.htmlSettings.ALLOWED_TAGS.filter(
        (tag) => !options.disabledTags?.includes(tag)
      );
    }

    text = DOMPurify.sanitize(text, this.htmlSettings);
    this.content = text;

    return this;
  }

  // take single line of text and splits it onto multiple rows
  static splitIntoRows(text: string, rows: number): string {
    if (text.indexOf("\n") !== -1) {
      return text;
    }
    const words = text.split(" ");

    // split words array into X equal parts
    const split = [];
    const chunkSize = Math.ceil(words.length / rows);
    for (let i = 0; i < rows; i++) {
      const part = words.slice(i * chunkSize, (i + 1) * chunkSize).join(" ");
      if (part) split.push(part);
    }

    return split.join("<br />");
  }

  public readonly toJsx = (): ReturnType<typeof domToReact> => {
    return parse(this.content, this.parseOptions);
  };

  readonly parseEmbed = (data: ElData) => {
    const embed = data.attribs["data-embed"];

    switch (embed) {
      case "example-insurance-card":
        return <ExampleInsuranceCard />;
      default:
        return <div />;
    }
  };

  private readonly createEmbedComponentVideo = (
    data: ElData
  ): ReactElement | undefined => {
    const { src = "", version = "default" } = data.attribs;

    const id = VimeoService.extractIdFromUrl(src);
    if (id) {
      return (
        <MediaPlayer
          videoId={id}
          videoProvider="vimeo"
          title=""
          playIcon="simple"
          inline={version === "inline"}
        />
      );
    }
  };

  private readonly parseUnderline = (data: ElData): ReactElement => {
    return (
      <Underline>{domToReact(data.children, this.parseOptions)}</Underline>
    );
  };

  static validateLink(href: string): boolean {
    const isTel = href.startsWith("tel:");
    const isMailto = href.startsWith("mailto:");
    if (isTel || isMailto) return true;

    const absoluteHref = href.startsWith("http")
      ? href
      : `${window.location.origin}${href}`;
    try {
      new URL(absoluteHref);
      return true;
    } catch (error) {
      reportErrorSentry(new Error(`Invalid link: ${href}`));
      return false;
    }
  }

  private anchorShorts: Record<string, (() => string) | undefined> = {
    telehealth: () => getLegalDocumentLink(Document.telehealthConsent),
    terms: () => getLegalDocumentLink(Document.termsOfService),
    "notice-privacy": () =>
      getLegalDocumentLink(Document.noticePrivacyPractice),
    "privacy-policy": () => getLegalDocumentLink(Document.privacyPolicy)
  };

  private readonly parseAnchorTag = (data: ElData): ReactElement => {
    const computed: {
      onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    } = {};
    let { href = "" } = data.attribs;

    if (!href) {
      return <>{domToReact(data.children, this.parseOptions)}</>;
    }

    const linkExpander = this.anchorShorts[href];
    if (linkExpander) {
      href = linkExpander();
    }
    const useDialog = href.includes("dialog=true");

    if (useDialog) {
      computed.onClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        const targetInnerText = e.currentTarget.innerText;
        AppQueryPopupsController.openPopup(AppPopup.iframe, {
          additionalParameters: {
            url: href,
            title: targetInnerText,
            stay: "false"
          }
        });
      };
    }

    let isExternalLink = href.startsWith("http") || href.startsWith("www");
    const validHref = HtmlParser.validateLink(href);
    let target = isExternalLink ? "_blank" : undefined;
    let useHref = validHref ? href : undefined;

    // handle broken relative links from zendesk or others
    if (useHref && useHref.startsWith("https://app/")) {
      target = undefined;
      useHref = useHref.replace("https://app/", "/app/");
      isExternalLink = false;
    }

    // handle absolute links to app
    if (
      useHref &&
      (useHref.startsWith("https://app.join9am.com") ||
        useHref.startsWith("https://app.9am.health"))
    ) {
      const url = new URL(useHref);
      const path = url.pathname;
      target = undefined;
      useHref = path;
      isExternalLink = false;
    }

    const props: {
      href?: string;
      to?: string;
      onClick?: (e: React.MouseEvent<HTMLElement>) => void;
      target?: string;
    } = {
      ...(isExternalLink
        ? {
            href: useHref
          }
        : { to: useHref }),
      target,
      ...computed
    };

    if (!validHref) {
      props.onClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        toast.error("error.invalid_link");
      };
    }

    return (
      <Link {...props}>{domToReact(data.children, this.parseOptions)}</Link>
    );
  };
  private readonly parseOptions: HTMLReactParserOptions = {
    replace: (el) => {
      const data = el as unknown as ElData;
      const { attribs = {}, name = "" } = data;

      if (attribs["data-embed"]) {
        return this.parseEmbed(data);
      }

      if (attribs.type === NineamComponents.video) {
        return this.createEmbedComponentVideo(data);
      }

      if (attribs.type === NineamComponents.feedback) {
        return <Feedback />;
      }

      if (name === "a") {
        return this.parseAnchorTag(data);
      }

      if (name === "u") {
        return this.parseUnderline(data);
      }

      if (this.options.emAsUnderline && name === "em") {
        return this.parseUnderline(data);
      }
    },
    htmlparser2: {
      lowerCaseTags: true
    }
  };
}

export default HtmlParser;
