import { requestIdleCallback } from "lib/requestIdleCallback";
import { Cubit } from "blac-next";
import { EmblaCarouselType } from "embla-carousel";

const CIRCLE_DEGREES = 360;
const WHEEL_ITEM_SIZE = 30;
const WHEEL_ITEM_COUNT = 18;
const WHEEL_ITEMS_IN_VIEW = 4;

export const WHEEL_ITEM_RADIUS = CIRCLE_DEGREES / WHEEL_ITEM_COUNT;
export const IN_VIEW_DEGREES = WHEEL_ITEM_RADIUS * WHEEL_ITEMS_IN_VIEW;
export const WHEEL_RADIUS = Math.round(
  WHEEL_ITEM_SIZE / 2 / Math.tan(Math.PI / WHEEL_ITEM_COUNT)
);

export type PickerItemValidCheck = (
  item: SlideItemProps,
  pickerId?: string
) => boolean;

type SlideState = {
  wheelReady: boolean;
  value?: string;
};

type SlideProps = {
  label?: string;
  value?: string;
  slides: SlideItemProps[];
  loop: boolean;
  name: string;
  validCheck?: PickerItemValidCheck;
  pickerId?: string;
};

export class PickerWheelItemBloc extends Cubit<SlideState, SlideProps> {
  static isolated = true;

  constructor(props: SlideProps) {
    super({ wheelReady: false, value: props.value });
  }

  setWheelRotation = (wheelRotation: number) => {
    if (!this.emblaApi) return;

    requestAnimationFrame(() => {
      if (!this.emblaApi) return;
      const getContainerStyles = this.getContainerStyles(wheelRotation);
      const containerEl = this.emblaApi.containerNode();
      containerEl.style.transform = getContainerStyles.transform;
      this.updateSlideStyles();
    });
  };

  updateSlideStyles = () => {
    if (!this.emblaApi) return;
    const slideElements = this.emblaApi.slideNodes();
    const styledSlides = this.getSlidesParsed(
      this.emblaApi,
      this.props?.loop ?? false,
      this.props?.slides.length ?? 0,
      this.totalRadius
    );

    for (let index = 0; index < slideElements.length; index += 1) {
      const slideElement = slideElements[index];
      const slideStyle = styledSlides[index].styles;
      for (const [key, value] of Object.entries(slideStyle)) {
        slideElement.style.setProperty(key, value);
      }
    }
  };

  setWheelReady = (wheelReady: boolean | (() => boolean)) => {
    if (typeof wheelReady === "boolean") {
      this.patch({ wheelReady });
    } else {
      this.patch({ wheelReady: wheelReady() });
    }
  };

  get spaceRequired(): number {
    let longestLabel = 0;
    this.props?.slides.forEach((item) => {
      if (item.label?.length || 0 > longestLabel) {
        longestLabel = item.label?.length ?? 0;
      }
    });

    return longestLabel * 18;
  }

  get totalRadius(): number {
    const length = this.props?.slides.length ?? 0;
    return length * WHEEL_ITEM_RADIUS;
  }

  get rotationOffset(): number {
    return this.props?.loop ? 0 : WHEEL_ITEM_RADIUS;
  }

  emblaApi: EmblaCarouselType | undefined;

  scrollToIndex = (index: number) => {
    if (!this.emblaApi) return;
    this.emblaApi.scrollTo(index);
  };

  scrollToValue = (value?: string) => {
    if (!this.emblaApi) return;
    if (!value) return;
    const index = this.props?.slides.findIndex((item) => item.value === value);
    if (index === -1 || typeof index === "undefined") return;
    this.scrollToIndex(index);
  };

  initComplete = false;
  setSlideReady = () => {
    if (!this.emblaApi) return;
    if (this.initComplete) {
      return;
    }

    this.handleEmblaSelect();

    this.initComplete = true;

    this.emblaApi.on("pointerUp", () => {
      if (!this.emblaApi) return;
      const { scrollTo, target, location } = this.emblaApi.internalEngine();
      const diffToTarget = target.get() - location.get();
      const factor = Math.abs(diffToTarget) < WHEEL_ITEM_SIZE / 2.5 ? 10 : 0.1;
      const distance = diffToTarget * factor;
      scrollTo.distance(distance, true);
    });

    this.emblaApi.on("scroll", () => {
      this.rotateWheel(this.emblaApi);
    });

    this.emblaApi.on("select", () => {
      this.handleEmblaSelect();
    });

    this.setWheelReady(true);
    this.inactivateEmblaTransform(this.emblaApi);
    this.rotateWheel(this.emblaApi);

    if (this.props?.value) {
      this.scrollToValue(this.props.value);
    } else {
      this.scrollToIndex(0);
    }
  };

  lastSelectEmitted: number | undefined = -1;
  handleEmblaSelect = (options: { emit?: boolean } = {}) => {
    if (!this.emblaApi) return;

    const { emit = true } = options;
    const previousSelected: number = this.emblaApi?.previousScrollSnap();
    const selected: number = this.emblaApi?.selectedScrollSnap();
    const isValid = this.checkIndexIsValid(selected);

    if (!isValid) {
      let walkerIndex = selected;
      const direction = previousSelected > selected ? 1 : -1;
      let foundValidIndex = false;

      let maxTries = this.props?.slides.length ?? 0;

      while (!foundValidIndex && maxTries > 0) {
        maxTries--;
        walkerIndex += direction;
        foundValidIndex = this.checkIndexIsValid(walkerIndex);
      }

      if (foundValidIndex) {
        requestIdleCallback(() => {
          this.scrollToIndex(walkerIndex);
        });
      }
    } else {
      const selected = this.emblaApi?.selectedScrollSnap();

      if (selected === this.lastSelectEmitted) return;
      this.lastSelectEmitted = selected;

      if (emit) {
        const rootNode = this.emblaApi?.rootNode();
        rootNode?.dispatchEvent(
          new CustomEvent("wheel:selected", {
            bubbles: true,
            composed: true,
            detail: {
              name: this.props?.name,
              value: this.props?.slides[selected].value
            }
          })
        );
      }

      setTimeout(() => {
        this.notifyOtherPickers();
      }, 10);
    }
  };

  notifyOtherPickers = async () => {
    const allPickers = this._blac.getAllBlocs(PickerWheelItemBloc, {
      searchIsolated: true
    });

    const others = allPickers.filter((picker) => picker !== this);

    for (const picker of others) {
      picker.updateSlideStyles();
      requestIdleCallback(() => {
        picker.selectClosestValid();
      });
    }
  };

  selectClosestValid = () => {
    if (!this.emblaApi) return;
    const selected = this.emblaApi.selectedScrollSnap();
    const isValid = this.checkIndexIsValid(selected);
    if (isValid) return;

    let searchBefore: number | undefined = undefined;
    let searchAfter: number | undefined = undefined;

    for (let index = selected; index >= 0; index--) {
      if (this.checkIndexIsValid(index)) {
        searchBefore = index;
        break;
      }
    }

    const length = this.props?.slides.length ?? 0;
    for (let index = selected; index < length; index++) {
      if (this.checkIndexIsValid(index)) {
        searchAfter = index;
        break;
      }
    }

    if (searchBefore === undefined && searchAfter === undefined) return;

    if (searchBefore === undefined) {
      if (!searchAfter) return;

      this.emblaApi.scrollTo(searchAfter);
      return;
    }

    if (searchAfter === undefined) {
      this.emblaApi.scrollTo(searchBefore);
      return;
    }

    const beforeDistance = selected - searchBefore;
    const afterDistance = searchAfter - selected;

    if (beforeDistance < afterDistance) {
      this.emblaApi.scrollTo(searchBefore);
    } else {
      this.emblaApi.scrollTo(searchAfter);
    }
  };

  rotateWheel = (emblaApi: EmblaCarouselType | undefined) => {
    if (!emblaApi) return;
    const scroll = emblaApi.scrollProgress();
    const length = this.props?.slides.length ?? 0;
    const rotation = length * WHEEL_ITEM_RADIUS - this.rotationOffset;
    this.setWheelRotation(rotation * scroll);
  };

  checkIndexIsValid = (index: number): boolean => {
    if (!this.emblaApi) return false;
    const indexPresent = this.props?.slides[index];
    if (typeof indexPresent === "undefined") return false;
    const isValid = this.props?.validCheck?.(
      this.props.slides[index],
      this.props.pickerId
    );
    if (isValid === undefined) return true;
    return isValid;
  };

  isInView = (wheelLocation: number, slidePosition: number): boolean =>
    Math.abs(wheelLocation - slidePosition) < IN_VIEW_DEGREES;

  getSlideStyles = (
    emblaApi: EmblaCarouselType,
    index: number,
    loop: boolean,
    slideCount: number,
    totalRadius: number
  ): SlideParsedType => {
    const wheelLocation = emblaApi.scrollProgress() * totalRadius;
    const positionDefault = emblaApi.scrollSnapList()[index] * totalRadius;
    const positionLoopStart = positionDefault + totalRadius;
    const positionLoopEnd = positionDefault - totalRadius;

    let inView = false;
    let angle = index * -WHEEL_ITEM_RADIUS;

    if (this.isInView(wheelLocation, positionDefault)) {
      inView = true;
    }

    if (loop && this.isInView(wheelLocation, positionLoopEnd)) {
      inView = true;
      angle = -CIRCLE_DEGREES + (slideCount - index) * WHEEL_ITEM_RADIUS;
    }

    if (loop && this.isInView(wheelLocation, positionLoopStart)) {
      inView = true;
      angle = -(totalRadius % CIRCLE_DEGREES) - index * WHEEL_ITEM_RADIUS;
    }

    const isValid = this.checkIndexIsValid(index);

    if (inView) {
      return {
        styles: {
          opacity: "1",
          color: isValid ? "var(--text-normal)" : "var(--text-disabled)",
          transform: `rotateX(${angle}deg) translateZ(${WHEEL_RADIUS}px)`
        },
        label: this.props?.slides[index].label ?? "",
        value: this.props?.slides[index].value
      };
    }

    return {
      styles: {
        opacity: "0",
        transform: "none"
      },
      label: this.props?.slides[index].label ?? "",
      value: this.props?.slides[index].value
    };
  };

  getContainerStyles = (
    wheelRotation: number
  ): Pick<SlideParsedType["styles"], "transform"> => ({
    transform: `translateZ(${WHEEL_RADIUS}px) rotateX(${wheelRotation}deg)`
  });

  getSlidesParsed = (
    emblaApi: EmblaCarouselType | undefined,
    loop: boolean,
    slideCount: number,
    totalRadius: number
  ): SlideParsedType[] => {
    const slidesStyles: SlideParsedType[] = [];

    for (let index = 0; index < slideCount; index += 1) {
      const slideStyle = emblaApi
        ? this.getSlideStyles(emblaApi, index, loop, slideCount, totalRadius)
        : ({} as SlideParsedType);
      slidesStyles.push(slideStyle);
    }
    return slidesStyles;
  };

  inactivateEmblaTransform = (emblaApi: EmblaCarouselType | undefined) => {
    if (!emblaApi) return;
    const { translate, slideLooper } = emblaApi.internalEngine();
    translate.clear();
    translate.toggleActive(false);
    slideLooper.loopPoints.forEach(
      ({
        translate
      }: {
        translate: {
          clear: () => void;
          toggleActive: (arg0: boolean) => void;
        };
      }) => {
        translate.clear();
        translate.toggleActive(false);
      }
    );
  };

  rootNodeSize = 0;
  readRootNodeSize = (emblaApi: EmblaCarouselType | undefined) => {
    if (!emblaApi) return 0;
    return emblaApi.rootNode().getBoundingClientRect().height;
  };

  resizeObserver: ResizeObserver | undefined;
  addResizeObserver = () => {
    if (!this.emblaApi) return;
    if (this.resizeObserver) return;
    if (!this.rootNodeSize) {
      this.rootNodeSize = this.readRootNodeSize(this.emblaApi);
    }

    this.resizeObserver = new ResizeObserver(() => {
      if (this.readRootNodeSize(this.emblaApi) !== this.rootNodeSize) {
        this.rootNodeSize = this.readRootNodeSize(this.emblaApi);
        this.setWheelReady(false);

        this.setWheelReady(() => {
          this.emblaApi?.reInit();
          this.inactivateEmblaTransform(this.emblaApi);
          this.rotateWheel(this.emblaApi);
          this.updateSlideStyles();
          return true;
        });
      }
    });

    this.resizeObserver.observe(this.emblaApi.rootNode());
  };

  removeResizeObserver = () => {
    this.resizeObserver?.disconnect();
  };
}

export type SlideItemProps = {
  label: string;
  value?: string;
};

export type SlideParsedType = {
  styles: {
    opacity: string;
    transform: string;
    color?: string;
  };
} & SlideItemProps;
