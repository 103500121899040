import styled from "@emotion/styled";

const Collection = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: var(--light-shadow);
  border-radius: var(--border-radius);
  margin: 1.5rem 0 0;

  &:first-of-type {
    margin-top: 0;
  }

  .link {
    display: flex;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: calc(100% - 2rem);
      height: 1px;
      background: #0000001a;
      position: absolute;
      top: 100%;
    }

    &:last-of-type::after {
      content: none;
    }
  }

  .icon {
    --size: 1.3rem;
    width: var(--size);
    display: flex;
    justify-content: center;
    background: var(--icon-bg, transparent);
    border-radius: 0.5rem;
    aspect-ratio: 1;
    align-items: center;

    svg {
      display: block;
      width: var(--icon-size, 1.3rem) !important;
      height: auto !important;
      opacity: var(--icon-opacity, 0.7);
    }

    &[data-size="large"] {
      --size: 2.75rem;
      --icon-size: 1.82rem;
      --icon-opacity: 1;
    }
  }

  .label {
    flex: 1;
    padding: 0 1rem 0 0;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 140%;
    letter-spacing: -0.02em;
  }

  .icon + .label {
    padding-left: 0.6rem;
  }

  .status {
    display: flex;
    align-items: baseline;
    margin: 0;
    font-size: 1rem;
    color: var(--color-charcoal);

    svg {
      display: inline-block;
      margin-right: 0.3rem;
      transform: translateY(0.02em) scale(1.2);
      width: 0.8em;
      height: auto;
      line-height: 1;
    }

    small {
      font-size: 0.875rem;
      color: var(--color-charcoal-60);
      font-weight: 400;
    }
  }

  .action {
    padding-left: 1rem;
  }

  .detail {
    margin: 0 0.5rem;
    font-size: 1rem;
    color: var(--color-charcoal);
    transform: translateY(0.1em);
    font-weight: 400;

    small {
      font-size: 0.875rem;
      color: var(--color-charcoal-60);
    }

    b,
    strong {
      font-weight: 500;
    }
  }

  .arrow {
    width: 1rem;
    position: relative;
    height: 100%;

    svg {
      display: block;
      width: 1.2rem;
      position: absolute;
      top: 0;
      transform: translate(0, -50%);
      height: auto;
      opacity: 0.25;
    }
  }
`;

export default Collection;
