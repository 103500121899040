import { Button } from "@9amhealth/shared";
import React from "react";
import AppVersionBloc from "src/hybrid/AppVersionBloc";
import { useBloc } from "src/state/state";

const DebugVersion = () => {
  const [{ current, latest, local = { bundles: [] }, pluginDetails }, { reset, setVersion }] =
    useBloc(AppVersionBloc);
  return (
    <div>
      <div>
        <b>Plugin: </b>
        <div>
          <pre>{JSON.stringify(pluginDetails, null, 2)}</pre>
        </div>
      </div>
      <div>
        <b>Current: </b>
        <div>
          <b>{current?.bundle.version}</b>
        </div>
      </div>
      <div>
        <b>Latest: </b>
        <div>
          <b>{latest?.version}</b>
        </div>
      </div>
      <hr />
      <div>
        <b>Local: </b>
        {local.bundles.map((v) => (
          <div key={v.id}>
            <b>{v.version}</b>
            <span>|status: {v.status}</span>
            {v.version !== current?.bundle.version ? (
              <Button small onPress={() => void setVersion(v)}>
                Set {v.version} as current
              </Button>
            ) : (
              <span>|ACTIVE</span>
            )}
          </div>
        ))}
      </div>
      <hr />
      {current?.bundle.version !== "builtin" && (
        <Button onPress={reset} small>
          Reset to builtin version
        </Button>
      )}
    </div>
  );
};

export default DebugVersion;
