/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable no-prototype-builtins */

import type { Dayjs } from "dayjs";
import type {
  CodeableConcept,
  Coding,
  Device,
  FhirResource,
  Observation,
  ObservationComponent,
  Quantity,
  Reference
} from "fhir/r4";
import {
  LoincCoding,
  LoincCodingCode,
  SYSTEM_BIOREF,
  SYSTEM_LOINC,
  SYSTEM_UNITSOFMEASURE,
  ValueDataForCodingCode
} from "src/constants/fhir";
import date, { DateFormats, dateLocal, dateLocalString } from "src/lib/date";
import loincCodeToName from "src/lib/loincCodeToName";
import reportErrorSentry from "src/lib/reportErrorSentry";
import {
  cmToFeetAndInches,
  cmToInch,
  kgToPounds
} from "src/lib/unitConversion";
import { temporalPeriodMap } from "src/ui/components/BloodGlucoseForm/BloodGlucoseTagSelector";
import translate from "./translate";

export interface CustomObservation extends Observation {
  sourceType?: string;
}

export enum FhirObservationType {
  questionnaire = "internal-questionnaire",
  bioReferenceLabs = "bioreference",
  diagnostics = "diagnostics",
  api = "api",
  none = "-none-"
}

export interface ParsedObservationData {
  id: string;
  code: string;
  value: string;
  valueNumber?: number;
  unit: string;
  date: Dayjs;
  hasTime: boolean;
  title: string;
  errors?: string[];
  source?: string;
  linkedObservations?: ParsedObservationData[];
  category: string[];
}

export interface StructuredObservation {
  code: LoincCodingCode | string;
  title: string;
  observations: CustomObservation[];
  last: CustomObservation;
  first: CustomObservation;
}

export interface ParsedObservationGroup {
  day: Dayjs;
  dayString?: string;
  observations: ParsedObservationData[];
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class Fhir {
  static categories: Record<string, CodeableConcept> = {
    vitalSigns: {
      coding: [
        {
          system: "http://terminology.hl7.org/CodeSystem/observation-category",
          code: "vital-signs",
          display: "Vital Signs"
        }
      ],
      text: "Vital Signs"
    },
    activity: {
      coding: [
        {
          system: "http://terminology.hl7.org/CodeSystem/observation-category",
          code: "activity",
          display: "Activity"
        }
      ],
      text: "Activity"
    }
  } as const;

  /**
   * Filter observations by code
   * @param observations
   * @param filter
   */
  public static filterObservations(
    observations: CustomObservation[],
    filter: {
      code?: (LoincCodingCode | string)[];
    }
  ) {
    let filtered = observations;

    if (filter.code) {
      filtered = filtered.filter((obs) => {
        const code = Fhir.getLoincCoding(obs.code.coding)?.code;
        return filter.code?.indexOf(code ?? "") !== -1;
      });
    }

    return filtered;
  }

  /**
   * Create a map of structured observations from a list of observations
   * @param observations
   */
  public static createStructuredObservations(
    observations: CustomObservation[]
  ): Map<string, StructuredObservation> {
    const structuredObservations = new Map<string, StructuredObservation>();

    for (const observation of observations) {
      const components = Fhir.getFhirObservationComponent(observation);
      const title = Fhir.getFhirCodingTitle(components);
      const code = Fhir.getLoincCoding(observation.code.coding)?.code;

      if (code) {
        const existing = structuredObservations.get(code);
        if (existing) {
          existing.observations.push(observation);
        } else {
          structuredObservations.set(code, {
            code,
            title,
            observations: [observation],
            last: observation,
            first: observation
          });
        }
      }
    }

    // sort the observations by date and set the first and last
    structuredObservations.forEach((observation) => {
      observation.observations = Fhir.sortObservationsByEffectiveDate(
        observation.observations
      );
      // eslint-disable-next-line @typescript-eslint/prefer-destructuring
      observation.last = observation.observations[0];
      observation.first =
        observation.observations[observation.observations.length - 1];
    });

    return structuredObservations;
  }

  public static getFhirObservationComponent(
    obs: CustomObservation | Observation | ObservationComponent
  ): ObservationComponent[] {
    const isMulti = obs.hasOwnProperty("component");

    if (isMulti) {
      const data = obs as CustomObservation | Observation;
      return data.component ?? [];
    } else {
      const data = obs as ObservationComponent;
      return [data];
    }
  }

  public static getFhirCodingTitle(component: ObservationComponent[]): string {
    let title;

    for (const item of component) {
      for (const coding of item.code.coding ?? []) {
        if (coding.system?.includes(SYSTEM_BIOREF)) {
          title = coding.display;
        }

        if (!title && coding.system === SYSTEM_LOINC) {
          title = coding.display;
        }
      }
    }

    return title ?? "Untitled";
  }

  public static sortObservationsByEffectiveDate(
    ovs: CustomObservation[]
  ): CustomObservation[] {
    return [...ovs].sort((a, b) => {
      const aDate = a.effectiveDateTime ? dateLocal(a.effectiveDateTime) : 0;
      const bDate = b.effectiveDateTime ? dateLocal(b.effectiveDateTime) : 0;
      if (aDate < bDate) {
        return 1;
      } else if (aDate > bDate) {
        return -1;
      }
      return 0;
    });
  }

  public static filterUniqueObservationsByCode(
    ovs: CustomObservation[]
  ): CustomObservation[] {
    const sorted = Fhir.sortObservationsByEffectiveDate(ovs);
    const uniqueObservations: CustomObservation[] = [];
    const codes: string[] = [];
    for (const o of sorted) {
      const code = Fhir.getLoincCoding(o.code.coding)?.code;
      if (code && codes.indexOf(code) === -1) {
        uniqueObservations.push(o);
        codes.push(code);
      }
    }
    return uniqueObservations;
  }

  public static filterObservationsBySource(
    ovs: CustomObservation[],
    source: FhirObservationType[]
  ): CustomObservation[] {
    const sorted = Fhir.sortObservationsByEffectiveDate(ovs);
    const matchSource: CustomObservation[] = [];
    for (const o of sorted) {
      const code = o.sourceType ?? FhirObservationType.none;

      for (const s of source) {
        if (code.indexOf(s) !== -1) {
          matchSource.push(o);
        }
      }
    }
    return matchSource;
  }

  public static humanReadableReferenceText(inputText?: string): string {
    let text = inputText ?? "";
    text = text.replace("<", " less than ").trim();
    text = text.replace(">", " greater than ").trim();
    text = text.replace("=", " equal to ").trim();
    text = text.replace("-", " to ").trim();
    return text;
  }

  public static createValueQuantity = (
    value: number,
    code: LoincCodingCode
  ): Quantity => ({
    value,
    unit: ValueDataForCodingCode(code).unit,
    system: SYSTEM_UNITSOFMEASURE,
    code: ValueDataForCodingCode(code).code,
    comparator: ValueDataForCodingCode(code).comparator
  });

  public static createObservationComponent(
    code: LoincCodingCode,
    value?: {
      value?: number;
    }
  ): ObservationComponent {
    return {
      code: LoincCoding(code),
      valueQuantity: value?.value
        ? Fhir.createValueQuantity(value.value, code)
        : undefined
    };
  }

  public static createObservation(options: {
    code: LoincCodingCode;
    status?: CustomObservation["status"];
    component?: ObservationComponent[];
    date?: string;
    sourceType?: FhirObservationType;
    valueQuantity?: Quantity;
    temporalPeriod?: string;
    category?: CodeableConcept[];
    device?: Reference;
    contained?: FhirResource[];
  }): CustomObservation {
    const dateAsIsoString = options.date ?? new Date().toISOString();

    const category = [...(options.category ?? [])];
    if (options.temporalPeriod) {
      category.push(temporalPeriodMap[options.temporalPeriod]);
    }

    return {
      resourceType: "Observation",
      status: options.status ?? "unknown",
      category,
      code: LoincCoding(options.code),
      effectiveDateTime: dateAsIsoString,
      component: options.component,
      sourceType: options.sourceType,
      valueQuantity: options.valueQuantity,
      device: options.device,
      contained: options.contained
    } satisfies CustomObservation;
  }

  public static getComponentFromObservationByCode(
    obs: CustomObservation,
    code: LoincCodingCode
  ): ObservationComponent | undefined {
    const components = Fhir.getFhirObservationComponent(obs);
    return components.find(
      (c) => Fhir.getLoincCoding(c.code.coding)?.code === code
    );
  }

  public static getValueFromComponent(
    component?: ObservationComponent
  ): number | undefined {
    return component?.valueQuantity?.value;
  }

  // Get a nice title for an observation, based on the observation loic code
  public static getObservationShortTitle(
    observation: CustomObservation
  ): string | undefined {
    const loincCoding = Fhir.getLoincCoding(observation.code.coding);
    if (loincCoding?.code) {
      return translate(loincCodeToName(loincCoding.code));
    }
  }

  public static getDisplayNameForObservation(
    obs: CustomObservation
  ): string | undefined {
    const observationCodeText =
      Fhir.getObservationShortTitle(obs) ?? obs.code.text;
    if (observationCodeText) {
      return observationCodeText;
    }

    const components = Fhir.getFhirObservationComponent(obs);
    return Fhir.getFhirCodingTitle(components);
  }

  public static getObservationDate(obs: CustomObservation): Dayjs {
    return date.isoStringDisplayDate(obs.effectiveDateTime ?? "");
  }

  public static ParsedObservations: Partial<{
    [key in LoincCodingCode]: (obs: CustomObservation) => ParsedObservationData;
  }> = {
    [LoincCodingCode.bloodPressure]: Fhir.parseBloodPressure,
    [LoincCodingCode.bloodPressureWithChildrenOptional]: Fhir.parseBloodPressure
  };

  public static parseObservationGeneric(
    obs: CustomObservation
  ): ParsedObservationData {
    const value = Fhir.getValueFromComponent(obs);
    const title = Fhir.getDisplayNameForObservation(obs);
    const code = Fhir.getLoincCoding(obs.code.coding)?.code;

    const unit = obs.valueQuantity?.unit;
    const valueString = value?.toString();

    const fallbackValue = obs.valueString;
    const dateContainsTime = obs.effectiveDateTime?.includes("T") ?? false;

    const HIDE_CATEGORIES = ["Vital Signs", "Activity"];
    const obsCategories = obs.category ?? [];
    const category = obsCategories
      .map((c) => c.text ?? "")
      .filter((c) => !HIDE_CATEGORIES.includes(c));

    return {
      code: code ?? "unknown",
      value: valueString ?? fallbackValue ?? "",
      valueNumber: value,
      unit: unit ?? "",
      date: Fhir.getObservationDate(obs),
      hasTime: dateContainsTime,
      title: title ?? "",
      source: obs.sourceType,
      id: obs.id ?? "",
      category
    };
  }

  public static parseBloodPressure(
    obs: CustomObservation
  ): ParsedObservationData {
    const defaultParsed = Fhir.parseObservationGeneric(obs);
    const systolicValue = Fhir.getValueFromComponent(
      Fhir.getComponentFromObservationByCode(
        obs,
        LoincCodingCode.bloodPressureSystolic
      )
    );
    const diastolicValue = Fhir.getValueFromComponent(
      Fhir.getComponentFromObservationByCode(
        obs,
        LoincCodingCode.bloodPressureDiastolic
      )
    );
    const title = Fhir.getDisplayNameForObservation(obs);

    return {
      ...defaultParsed,
      value: `${systolicValue}/${diastolicValue}`,
      valueNumber: systolicValue,
      unit: "mmHg",
      date: Fhir.getObservationDate(obs),
      title: title ?? translate("bloodPressure")
    };
  }

  public static getLoincCoding(
    coding: Coding[] | undefined
  ): Coding | undefined {
    if (!coding) return;
    return coding.find((c) => c.system === SYSTEM_LOINC);
  }

  public static autoParseObservation(
    obs?: CustomObservation
  ): ParsedObservationData | undefined {
    if (!obs) {
      return undefined;
    }

    const observationCodings = obs.code.coding ?? [];
    const loincCoding = Fhir.getLoincCoding(observationCodings);

    const parsed =
      Fhir.ParsedObservations[loincCoding?.code as LoincCodingCode]?.(obs) ??
      Fhir.parseObservationGeneric(obs);

    if (parsed && parsed.value.indexOf("undefined") !== -1) {
      reportErrorSentry(
        new Error(
          `Parsed value for ${parsed.title} is ${parsed.value} which is invalid`
        )
      );
      parsed.errors = ["Invalid value"];
    }

    return parsed;
  }

  // Group a list of observations by their effective date (day), and sort them by date (newest first)
  public static groupObservationsByDate(
    observations: CustomObservation[]
  ): ParsedObservationGroup[] {
    const orderedGroups: ParsedObservationGroup[] = [];
    const groupedByDate = new Map<string, ParsedObservationData[]>();

    observations.forEach((obs) => {
      const obsDate = Fhir.getObservationDate(obs);
      const key = obsDate.format(DateFormats.ISO_YMD);
      const parsed = Fhir.autoParseObservation(obs);

      if (parsed) {
        const current = groupedByDate.get(key) ?? [];
        groupedByDate.set(key, [...current, parsed]);
      }
    });

    // clean up groups
    for (const [key, value] of groupedByDate) {
      let clean: ParsedObservationData[] = [...value];
      // remove observations with the same code and same value
      const uniqueObservations: ParsedObservationData[] = [];
      clean.forEach((obs) => {
        const existing = uniqueObservations.find(
          (o) => o.code === obs.code && o.value === obs.value
        );
        if (!existing) {
          uniqueObservations.push(obs);
        }
      });
      clean.splice(0, clean.length, ...uniqueObservations);

      // go through all observations and link them if they have the same code
      clean.forEach((obs) => {
        const linkedObservations = clean.filter(
          (o) => o.code === obs.code && o.value !== obs.value
        );
        if (linkedObservations.length > 0) {
          obs.linkedObservations = linkedObservations;
        }
      });

      // delete duplicate observations with the same code, keep the newest one
      clean = clean.filter(
        (obs, index, self) =>
          index ===
          self.findIndex(
            (o) =>
              o.code === obs.code &&
              o.date.toDate().getTime() <= obs.date.toDate().getTime()
          )
      );

      // sort all groups alphabetically by title
      clean.sort((a, b) => a.title.localeCompare(b.title));

      groupedByDate.set(key, clean);
    }

    Object.keys(groupedByDate).sort(
      (a, b) => new Date(b).getTime() - new Date(a).getTime()
    );

    groupedByDate.forEach((obsForDate, obsDate) => {
      const day = date.isoStringDisplayDate(obsDate);
      orderedGroups.push({
        observations: obsForDate,
        day,
        dayString: day.format(DateFormats.DISPLAY_US_MDY)
      });
    });

    // sort by date
    orderedGroups.sort(
      (a, b) => b.day.toDate().getTime() - a.day.toDate().getTime()
    );

    return orderedGroups;
  }

  /**
   * Get the latest observation from a list of observations
   * @param all
   */
  static getLatestObservation(
    all: CustomObservation[]
  ): CustomObservation | undefined {
    if (all.length === 0) {
      return undefined;
    }

    const sorted = Fhir.sortObservationsByEffectiveDate(all);
    return sorted[0];
  }

  /**
   * Convert a date to a human readable string (e.g. "Today", "Yesterday", "2 days ago", "1 week ago", "1 month ago")
   * @param displayDate
   */
  static displayTimeAgo(displayDate?: Dayjs): string {
    if (!displayDate) {
      return "";
    }

    const now = dateLocal();
    const diffDays = now.diff(displayDate, "day");

    if (diffDays === 0) {
      return translate("today");
    }

    if (diffDays === 1) {
      return translate("yesterday");
    }

    if (diffDays < 7) {
      return translate("daysAgo", { days: diffDays });
    }

    const diffWeeks = now.diff(displayDate, "week");
    const diffMonths = now.diff(displayDate, "month");

    if (diffWeeks >= 1 && diffMonths === 0) {
      return translate("weekAgo", { count: diffWeeks });
    }

    if (diffMonths <= 6) {
      return translate("monthAgo", { count: diffMonths });
    }

    return dateLocalString(displayDate);
  }

  /**
   * Takes a CustomObservation and returns a ParsedObservationData object
   * @param obsData
   */
  static localObservationData = (
    obsData: ParsedObservationData
  ): ParsedObservationData => {
    // turn cm to feet and inches
    if (obsData.unit === "cm") {
      if ((obsData.code as LoincCodingCode) === LoincCodingCode.height) {
        // convert body height value to feet and inches
        const { feet, inches } = cmToFeetAndInches(obsData.value);
        obsData.value = `${feet}' ${inches}"`;
      } else {
        // convert other values to inches
        const inches = Math.round(cmToInch(obsData.value));
        obsData.value = `${inches}"`;
      }
      obsData.unit = "";
    }

    // turn kg to lbs
    if (obsData.unit === "kg") {
      obsData.value = `${Math.round(kgToPounds(obsData.value))}`;
      obsData.unit = translate("lbs");
    }

    // round long decimals to 2 places
    const valueHasDecimal = obsData.value.toString().includes(".");
    const valueAsFloat = parseFloat(obsData.value.toString());
    const valueIsValidNumber =
      !isNaN(valueAsFloat) &&
      obsData.value.toString() === valueAsFloat.toString();

    if (valueHasDecimal && valueIsValidNumber) {
      let roundToDecimalPlaces = 2;

      const roundToOneDecimalPlaceCodes = [
        LoincCodingCode.hba1c,
        LoincCodingCode.bmi,
        LoincCodingCode.weight
      ];

      if (
        roundToOneDecimalPlaceCodes.includes(obsData.code as LoincCodingCode)
      ) {
        roundToDecimalPlaces = 1;
      }

      obsData.value = valueAsFloat.toFixed(roundToDecimalPlaces);
    }

    // remove trailing 0 in decimal (if number is rounded to a whole number), 1.0 = 1, 1.40 = 1.4
    if (obsData.value.toString().includes(".")) {
      obsData.value = obsData.value.toString().replace(/\.?0*$/, "");
    }

    return obsData;
  };

  static sortLoincCodes(codes: string[]): string[] {
    const sorted = [...codes];
    sorted.sort((a, b) => a.localeCompare(b));
    return sorted;
  }

  static knownDeviceCodings: Record<string, Coding> = {
    "activity-tracker": {
      system: "9am-backend/device-type",
      code: "ACTIVITY_TRACKER",
      display: "Activity tracker"
    }
  };

  /**
   * Create a device object
   */
  static createDevice(deviceData: {
    id: string;
    coding?: keyof typeof Fhir.knownDeviceCodings;
    modelNumber?: string;
    manufacturer?: string;
    status?: Device["status"];
  }): Device {
    const device: Device = {
      id: deviceData.id,
      status: deviceData.status,
      resourceType: "Device",
      modelNumber: deviceData.modelNumber,
      manufacturer: deviceData.manufacturer,
      type: deviceData.coding
        ? Fhir.knownDeviceCodings[deviceData.coding]
        : undefined,
      deviceName: deviceData.modelNumber
        ? [
            {
              type: "model-name",
              name: deviceData.modelNumber
            }
          ]
        : undefined
    };
    return device;
  }
}
