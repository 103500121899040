import {
  LabOrderControllerService,
  UserPreferencesControllerService,
  ZipCodeLookupControllerService
} from "@9amhealth/openapi";
import { Cubit } from "blac";
import type { Dict } from "mixpanel-browser";
import { globalEvents } from "src/constants/globalEvents";
import { LabProvider } from "src/constants/labProviders";
import type { Locale } from "src/constants/language";
import { stateCodeToNameMap } from "src/constants/stateCodeToNameMap";
import createTrackEvent from "src/lib/createTrackEvent";
import { dateGuessTz, dateLocal } from "src/lib/date";
import formatPhoneNumber from "src/lib/formatPhoneNumber";
import type { Journey } from "src/lib/getEducationalContent";
import { changeLanguage, getSupportedUserLocale } from "src/lib/i18next";
import { isAndroid } from "src/lib/platform";
import reportErrorSentry from "src/lib/reportErrorSentry";
import {
  apiMiddleware,
  historyState,
  tracker,
  userState
} from "src/state/state";
import type { EducationalContent } from "src/ui/components/UserEducationalFeed/UserEducationalFeedBloc";
import { StorageController } from "../StorageBloc/StorageBloc";

export enum UserPreferenceKeys {
  onboardingDone = "onboarding.done",
  establishCareCompleted = "onboarding.establish-care-completed",
  healthInformationExchangeCompleted = "onboarding.health-information-exchange-completed",
  userDateOfBirth = "user.date-of-birth",
  userSex = "user.sex",
  userFirstName = "auth.user.firstname",
  userLastName = "auth.user.lastname",
  registerFunnel = "user.register-funnel",
  partnerProgram = "user.partner.program",
  lastSignupFunnel = "user.last-signup-funnel",
  signupFunnelStatus = "user.signup-funnel-status",
  aptSuite = "subscription.address.shipment.default.apt-suite",
  city = "subscription.address.shipment.default.city",
  country = "subscription.address.shipment.default.country",
  firstName = "subscription.address.shipment.default.first-name",
  lastName = "subscription.address.shipment.default.last-name",
  state = "subscription.address.shipment.default.state",
  street = "subscription.address.shipment.default.street",
  zip = "subscription.address.shipment.default.zip",
  language = "user.language",
  timezone = "user.timezone",
  requestedPlanChanges = "user.requested-plan-changes",
  appUserPushNotificationAccessRequestAction = "app.user.push-notification-access-request-action",
  appUserBuckets = "app.user.buckets",
  pushNotificationsCareTeam = "notifications.care-team-message.push",
  smsNotificationsCareTeam = "notifications.care-team-message.sms",
  userHasDemoData = "user.has-demo-data",
  userEducationalContent = "user.careteamassigned.educationalcontent",
  physicianPrimaryPhone = "physicians.primary.phone-number",
  physicianPrimaryFax = "physicians.primary.fax-number",
  journeyEnrollment = "user.journey.enrollments"
}
export type UserPreferenceSex = "female" | "male";

interface LabProviderCheckResult {
  error: string;
  providers: string[];
}

export enum UserExclusiveBucketCategoryName {
  PushDialog = "push-dialog"
}

export enum UserBucket {
  PushDialogAggro = "push-dialog-aggro",
  PushDialogSoft = "push-dialog-soft"
}

export type UserExclusiveBucketCategories = Record<
  UserExclusiveBucketCategoryName,
  readonly UserBucket[]
>;

type UserPushRequestAction = "denied" | "granted" | "not-now";

export interface UserPreferences {
  loading: boolean | null;
  [UserPreferenceKeys.partnerProgram]?: string;
  [UserPreferenceKeys.onboardingDone]?: string;
  [UserPreferenceKeys.establishCareCompleted]?: boolean;
  [UserPreferenceKeys.healthInformationExchangeCompleted]?: boolean;
  [UserPreferenceKeys.userDateOfBirth]?: string;
  [UserPreferenceKeys.userSex]?: UserPreferenceSex;
  [UserPreferenceKeys.registerFunnel]?: string;
  [UserPreferenceKeys.lastSignupFunnel]?: string;
  [UserPreferenceKeys.signupFunnelStatus]?: string;
  [UserPreferenceKeys.aptSuite]?: string;
  [UserPreferenceKeys.city]?: string;
  [UserPreferenceKeys.country]?: string;
  [UserPreferenceKeys.firstName]?: string;
  [UserPreferenceKeys.lastName]?: string;
  [UserPreferenceKeys.state]?: string;
  [UserPreferenceKeys.street]?: string;
  [UserPreferenceKeys.zip]?: string;
  [UserPreferenceKeys.language]?: string;
  [UserPreferenceKeys.timezone]?: string;
  [UserPreferenceKeys.requestedPlanChanges]?: string;
  [UserPreferenceKeys.userFirstName]?: string;
  [UserPreferenceKeys.userLastName]?: string;
  [UserPreferenceKeys.appUserBuckets]?: UserBucket[];
  [UserPreferenceKeys.appUserPushNotificationAccessRequestAction]?: UserPushRequestAction;
  [UserPreferenceKeys.pushNotificationsCareTeam]?: boolean;
  [UserPreferenceKeys.smsNotificationsCareTeam]?: boolean;
  [UserPreferenceKeys.userHasDemoData]?: boolean;
  [UserPreferenceKeys.userEducationalContent]?: EducationalContent;
  [UserPreferenceKeys.physicianPrimaryPhone]?: string;
  [UserPreferenceKeys.physicianPrimaryFax]?: string;
  [UserPreferenceKeys.journeyEnrollment]?: (Journey | undefined)[];
}

const reflectPreferencesInProfile: UserPreferenceKeys[] = [
  UserPreferenceKeys.appUserPushNotificationAccessRequestAction,
  UserPreferenceKeys.appUserBuckets,
  UserPreferenceKeys.lastSignupFunnel,
  UserPreferenceKeys.registerFunnel,
  UserPreferenceKeys.signupFunnelStatus
];

export default class UserPreferencesCubit extends Cubit<UserPreferences> {
  setupDone = false;

  exclusiveUserBuckets: UserExclusiveBucketCategories = {
    // Only one of these can be set at a time
    [UserExclusiveBucketCategoryName.PushDialog]: [
      UserBucket.PushDialogAggro,
      UserBucket.PushDialogSoft
    ] as const
  };

  constructor() {
    super({
      loading: false
    });
    window.addEventListener(globalEvents.USER_CLEAR, () => {
      this.emit({
        loading: false
      });
    });

    const cached =
      StorageController.activeUserId &&
      StorageController.getItem(this.cacheKey);
    if (cached) {
      this.emit(JSON.parse(cached) as UserPreferences);
    }
  }

  cacheKey = "preferences";
  emitAndCache = (state: UserPreferences): void => {
    this.emit(state);
    StorageController.setItem(
      this.cacheKey,
      JSON.stringify({ ...state, loading: false })
    );
  };

  reset = (): void => {
    this.emit({
      loading: false
    });
    this.setupDone = false;
  };

  readonly setupUserProperties = async (): Promise<void> => {
    // load the user preferences
    await this.loadUserPreferences();

    // all preferences that need to be updated
    const updatedPreferences: Partial<UserPreferences> = {};

    // get the user preferred language from browser settings
    const supportedLocale =
      historyState.initialLocale ?? getSupportedUserLocale();

    if (typeof this.state[UserPreferenceKeys.language] === "undefined") {
      // Set the language preference
      updatedPreferences[UserPreferenceKeys.language] = supportedLocale;
    }

    // get user timezone
    const timezone = dateGuessTz();
    if (timezone !== this.state[UserPreferenceKeys.timezone]) {
      // Set the timezone preference
      updatedPreferences[UserPreferenceKeys.timezone] = timezone;
    }

    // if any preference needs to be updated, do it now
    if (Object.keys(updatedPreferences).length > 0) {
      await this.updateUserPreferences(updatedPreferences);
    }

    this.setupDone = true;
  };

  fetchPreferences = async (): Promise<UserPreferences | undefined> =>
    apiMiddleware.cached(
      async () => (await UserPreferencesControllerService.getPreferences()).data
    ) as unknown as UserPreferences;

  public readonly loadUserPreferences = async (): Promise<
    UserPreferences | undefined
  > => {
    if (userState.isTempUser) return;
    this.emitAndCache({ ...this.state, loading: true });

    try {
      // If we have a promise, we are already loading
      const data = await this.fetchPreferences();
      if (data?.[UserPreferenceKeys.language])
        void changeLanguage(data[UserPreferenceKeys.language]);

      this.emitAndCache({
        ...data,
        loading: false
      });

      return data as unknown as UserPreferences;
    } catch (e: unknown) {
      reportErrorSentry(e);
      this.emit({
        loading: false
      });
    }
  };

  parsePreferenceValue = <
    K extends keyof UserPreferences,
    V = UserPreferences[K]
  >(
    key: K,
    value: V
  ): V => {
    switch (key) {
      case UserPreferenceKeys.physicianPrimaryPhone:
      case UserPreferenceKeys.physicianPrimaryFax:
        return formatPhoneNumber(String(value)) as V;

      default:
        return value;
    }
  };

  prepareDataForParser = <
    K extends keyof UserPreferences,
    V = UserPreferences[K]
  >(
    preferences: Partial<UserPreferences>
  ): Omit<UserPreferences, "loading"> => {
    const newObject: Partial<Record<K, V>> = {};

    for (const key in preferences) {
      if (key !== "loading") {
        let value = preferences[key as K] as V;
        value = this.parsePreferenceValue(key as K, value);
        newObject[key as K] = value;
      }
    }

    return newObject;
  };

  public readonly updateUserPreferences = async (
    newPreferences: Partial<UserPreferences>
  ): Promise<void> => {
    void changeLanguage(
      (newPreferences[UserPreferenceKeys.language] as Locale) ??
        this.state[UserPreferenceKeys.language]
    );

    this.emitAndCache({
      ...this.state,
      loading: true,
      ...newPreferences
    });

    try {
      const sendData = this.prepareDataForParser(newPreferences);
      const { data } =
        await UserPreferencesControllerService.updatePreferences(sendData);

      apiMiddleware.clearAll();

      this.emitAndCache({
        ...data,
        loading: false
      });

      const propsForTracking = Object.keys(newPreferences).reduce<Dict>(
        (acc, key) => {
          const shouldReflectPreferencesInProfile =
            reflectPreferencesInProfile.find((k) => key.indexOf(k) > -1);
          const value = (newPreferences as Dict)[key] as string | undefined;
          if (value && shouldReflectPreferencesInProfile) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      tracker.setCustomUserAttributes(propsForTracking);
    } catch (e: unknown) {
      reportErrorSentry(e);
      this.emitAndCache({
        ...this.state,
        loading: false
      });
    }
  };

  public readonly checkZipCode = async (
    zipCode: string
  ): Promise<{ state: string } | undefined> => {
    let result = undefined;

    try {
      const { data } =
        await ZipCodeLookupControllerService.getStateByZipCode(zipCode);
      result = { state: data.state };
    } catch (e: unknown) {
      const networkError = e as NetworkError | undefined;

      if (networkError?.status !== 404) {
        reportErrorSentry(e);
      }
    }

    return result;
  };

  public readonly checkAvailableLabOrderProviders = async (
    zipCode: string
  ): Promise<LabProviderCheckResult> => {
    try {
      // check if zip is valid
      const zipCheckResponse = await this.checkZipCode(zipCode);
      const stateName = stateCodeToNameMap[zipCheckResponse?.state ?? ""];
      if (!stateName) {
        return {
          error: "invalid_zip_code",
          providers: []
        };
      }

      // set the users zip code in preferences
      await this.updateUserPreferences({
        [UserPreferenceKeys.zip]: zipCode
      });

      // get the supported providers for the user
      const availableProvidersRes =
        await LabOrderControllerService.availableLabOrderProviders();

      // track the zip code check
      tracker.track(createTrackEvent("Check Available Lab Providers"), {
        data: {
          "ZIP Code": zipCode,
          State: stateName,
          Providers: availableProvidersRes.data.labOrderProviders
        }
      });

      return {
        error: "",
        providers: availableProvidersRes.data.labOrderProviders
      };
    } catch (error: unknown) {
      return {
        error: "error_generic",
        providers: []
      };
    }
  };

  public readonly isHomePhlebotomySupported = (
    availableProviders: string[]
  ): boolean =>
    UserPreferencesCubit.isHomePhlebotomySupported(availableProviders);

  static isHomePhlebotomySupported = (
    availableProviders: string[]
  ): boolean => {
    const atHomeLabSupported =
      availableProviders.includes(LabProvider.BIO_REFERENCE_LAB) ||
      availableProviders.includes(LabProvider.GETLABS);

    return atHomeLabSupported;
  };

  /**
   * Get the user's buckets
   */
  get userBuckets(): UserBucket[] {
    return this.state[UserPreferenceKeys.appUserBuckets] ?? [];
  }

  /**
   * Check if the user has a bucket
   * @param bucket bucket to check
   * @returns true if the user has the bucket, false otherwise
   */
  public readonly hasUserBucket = (bucket: UserBucket): boolean => {
    return this.userBuckets.includes(bucket);
  };

  /**
   * Check if the bucket is in conflict with any other bucket
   * @param bucket bucket to check
   * @returns true if the bucket is in conflict with another bucket, false otherwise
   */
  public readonly checkBucketConflicts = (bucket: UserBucket): boolean => {
    // check if bucket conflicts in any of the exclusive buckets
    const allExclusiveUserBuckets = Object.values(
      this.exclusiveUserBuckets
    ) as unknown as UserBucket[][];

    for (const exclusiveUserBuckets of allExclusiveUserBuckets) {
      if (exclusiveUserBuckets.includes(bucket)) {
        // check if the user has any of the exclusive buckets
        for (const exclusiveUserBucket of exclusiveUserBuckets) {
          if (this.hasUserBucket(exclusiveUserBucket)) {
            return true;
          }
        }
      }
    }

    return false;
  };

  /**
   * Add a bucket to the user's buckets
   * @param bucket bucket to add
   * @returns true if the bucket was added, false if it was not added due to a conflict
   */
  public readonly addUserBucket = async (
    bucket: UserBucket
  ): Promise<boolean> => {
    if (this.checkBucketConflicts(bucket)) {
      reportErrorSentry(
        new Error(
          `User bucket ${bucket} conflicts with another bucket in exclusiveUserBuckets`
        )
      );
      return false;
    }

    const newUserBuckets = [...this.userBuckets, bucket];

    await this.updateUserPreferences({
      [UserPreferenceKeys.appUserBuckets]: newUserBuckets
    });

    tracker.track(createTrackEvent("Add User Bucket"), {
      data: {
        Bucket: bucket,
        "New User Buckets": newUserBuckets
      }
    });

    return true;
  };

  /**
   * Remove a bucket from the user's buckets
   * @param bucket bucket to remove
   */
  public readonly removeUserBucket = async (
    bucket: UserBucket
  ): Promise<void> => {
    const userHasBucket = this.hasUserBucket(bucket);
    if (!userHasBucket) {
      return;
    }

    const newUserBuckets = this.userBuckets.filter((b) => b !== bucket);

    await this.updateUserPreferences({
      [UserPreferenceKeys.appUserBuckets]: newUserBuckets
    });

    tracker.track(createTrackEvent("Remove User Bucket"), {
      data: {
        Bucket: bucket,
        "New User Buckets": newUserBuckets
      }
    });
  };

  /**
   * Choose a random bucket from the list of buckets and add it to the user's buckets
   * @param buckets list of buckets to choose from
   * @returns one of the buckets in the list
   */
  public readonly chooseRandomBucket = async (
    buckets:
      | UserBucket[]
      | UserExclusiveBucketCategoryName
      | readonly UserBucket[]
  ): Promise<UserBucket> => {
    if (typeof buckets === "string") {
      buckets = this.exclusiveUserBuckets[buckets];
    }

    return buckets[Math.floor(Math.random() * buckets.length)];
  };

  /**
   * Get the user's bucket for the given category
   * @param category category to get the bucket for
   * @returns the user's bucket in the category, or undefined if the user does not have a bucket in the category
   */
  public readonly getUserBucketForCategory = (
    category: UserExclusiveBucketCategoryName
  ): UserBucket | undefined => {
    const buckets = this.exclusiveUserBuckets[category] as
      | UserBucket[]
      | undefined;

    if (!buckets) {
      throw new Error(`User bucket category ${category} does not exist`);
    }

    return this.userBuckets.find((b) => buckets.includes(b));
  };

  /**
   * Selects the userBucket that is active for the user in the given category
   * if the user does not have a bucket in the category, one is chosen randomly
   * when using this you can be confident that the same bucket will be returned for the same user, unless the bucket is removed
   * @param category category to use
   * @returns the user's bucket in the category
   * @throws an error if the category is not in the map
   */
  public readonly selectBucketForCategory = async <
    T extends UserExclusiveBucketCategoryName
  >(
    category: T
  ): Promise<UserBucket> => {
    let presetBucket = undefined;

    // android is always soft
    if (isAndroid()) {
      presetBucket = UserBucket.PushDialogSoft;
    }

    const userBucket = presetBucket ?? this.getUserBucketForCategory(category);

    if (userBucket) {
      return userBucket;
    } else {
      const chosen = await this.chooseRandomBucket(category);
      // save the bucket to the user's preferences in the background
      void this.addUserBucket(chosen);
      return chosen;
    }
  };

  public readonly checkUserHasShippingAddress = async (): Promise<boolean> => {
    try {
      await this.loadUserPreferences();
      const street = this.state[UserPreferenceKeys.street];

      return Boolean(street);
    } catch (error) {
      reportErrorSentry(error);
    }

    return false;
  };

  /**
   * GETTER HELPERS FOR USER PREFERENCES
   */

  get firstName(): string | undefined {
    return this.state[UserPreferenceKeys.firstName];
  }

  get lastName(): string | undefined {
    return this.state[UserPreferenceKeys.lastName];
  }

  get language() {
    return this.state[UserPreferenceKeys.language];
  }

  get displayName(): string | undefined {
    return `${
      this.state[UserPreferenceKeys.userFirstName] ??
      this.state[UserPreferenceKeys.firstName]
    } ${
      this.state[UserPreferenceKeys.userLastName] ??
      this.state[UserPreferenceKeys.lastName]
    }`;
  }

  get pushNotificationsCareTeamEnabled(): boolean {
    const pref =
      this.state[UserPreferenceKeys.pushNotificationsCareTeam] ?? true;
    return Boolean(pref);
  }

  get smsNotificationsCareTeamEnabled(): boolean {
    const pref =
      this.state[UserPreferenceKeys.smsNotificationsCareTeam] ?? true;
    return Boolean(pref);
  }

  get lastSignupFunnel(): string | undefined {
    return this.state[UserPreferenceKeys.lastSignupFunnel];
  }

  public get journeys() {
    return this.state[UserPreferenceKeys.journeyEnrollment] ?? [];
  }

  get userAge(): number | undefined {
    const dob = this.state[UserPreferenceKeys.userDateOfBirth];
    if (!dob) return undefined;
    const asDate = dateLocal(dob);
    const now = dateLocal();
    const age = now.diff(asDate, "years");
    return age;
  }

  get isDemoUser(): boolean {
    return Boolean(this.state[UserPreferenceKeys.userHasDemoData]);
  }

  get isEstablishCareCompleted(): boolean {
    return Boolean(this.state[UserPreferenceKeys.establishCareCompleted]);
  }

  get isHealthInformationExchangeCompleted(): boolean {
    return Boolean(
      this.state[UserPreferenceKeys.healthInformationExchangeCompleted]
    );
  }
}
