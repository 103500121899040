import { Cubit } from "blac";
import VimeoService, { VimeoVideoMetaData } from "src/api/VimeoService";
import reportErrorSentry from "src/lib/reportErrorSentry";

export interface VideoData {
  thumb: string;
  width: number;
  height: number;
  fullscreen: boolean;
  inline?: boolean;
  title: string;
  videoWidth?: number;
  videoHeight?: number;
  ended?: boolean;
}

export type VideoPlayerLibInterface = {
  on: (event: string, callback: () => void) => void;
  off: (event: string, callback: () => void) => void;
  play: () => Promise<void>;
  pause: () => void;
  currentTime: () => number;
  duration: () => number;
  isFullscreen: () => boolean;
  dispose: () => void;
  isDisposed: () => boolean;
};

export default class VideoPlayerCubit extends Cubit<Record<string, VideoData>> {
  portalTarget: HTMLElement | null = null;

  metadata: Record<string, VimeoVideoMetaData | undefined> = {};

  constructor() {
    super({});
  }

  public readonly getVimeoVideoSource = async (
    id: number
  ): Promise<
    {
      src: string;
      type: "video/mp4";
    }[]
  > => {
    try {
      const vimeoData = VimeoService.fetchVideoMetadata(id);
      const { files } = await vimeoData;

      // select based on the width
      const selected =
        files?.find((file) => file.rendition === "720p") ?? files?.[0];

      if (!selected) {
        return [];
      }

      return [
        {
          type: "video/mp4",
          src: selected.link
        }
      ];
    } catch (error) {
      reportErrorSentry(error);
    }
    return [];
  };

  public readonly addVimeoVideo = (id: number): void => {
    void this.loadVimeoData(id);
  };

  isVideoWatched = (id: number): boolean => {
    return !!localStorage.getItem(`9am-video-${id}-watched`);
  };

  markVideoEnded = (id: number): void => {
    localStorage.setItem(`9am-video-${id}-watched`, "true");
    this.emit({
      ...this.state,
      [id]: { ...this.state[id], ended: true }
    });
  };

  private readonly loadVimeoData = async (id: number): Promise<void> => {
    try {
      const data = this.metadata[id] ?? (await VimeoService.getVideoMeta(id));
      this.metadata[id] = data;
      const { pictures } = data;

      const mainThumb = (pictures?.sizes ?? []).find(
        (size) => size.width === 640
      );
      if (!mainThumb) {
        return;
      }

      const newItem: VideoData = {
        fullscreen: false,
        thumb: mainThumb.link ?? "",
        width: mainThumb.width ?? 0,
        height: mainThumb.height ?? 0,
        title: data.name ?? "",
        videoWidth: data.width ?? 0,
        videoHeight: data.height ?? 0
      };

      this.emit({
        ...this.state,
        [id]: newItem
      });
    } catch (e: unknown) {
      reportErrorSentry(e);

      this.emit({
        ...this.state,
        [id]: {
          fullscreen: false,
          thumb: "",
          width: 0,
          height: 0,
          title: ""
        }
      });
    }
  };
}
