/* eslint-disable */

import { SanityAssetDocument, SanityImageAssetDocument } from "@sanity/client";
import sanityClient from "src/sanityClient";

export interface SanityPartialAssetRef {
  asset: {
    _ref: string;
  };
}

export type SanityImageMetadataPalletSwatch = {};

export type SanityResolvedDocument =
  | SanityAssetDocument
  | SanityImageAssetDocument;

export type ResolveRefListItem = [
  string,
  (data: SanityResolvedDocument) => void
];

export default class SanityService {
  public static fetchCachedMaxAge = 1000 * 60 * 60; // 60 minutes
  public static fetchCachedSanityData = new Map<string, unknown[]>();
  public static fetchCachedSanityDataTimestamp = new Map<string, number>();

  public static fetchSanityData = async <T>(query: string): Promise<T[]> => {
    try {
      // check if cached
      const cachedData = SanityService.fetchCachedSanityData.get(query);
      const cachedTimestamp =
        SanityService.fetchCachedSanityDataTimestamp.get(query);

      // if cached, return cached data
      if (
        cachedData &&
        cachedTimestamp &&
        cachedTimestamp + SanityService.fetchCachedMaxAge > Date.now()
      ) {
        return cachedData as T[];
      }

      // load fresh
      const response = await sanityClient.fetch<T[]>(query);

      // cache response data
      this.fetchCachedSanityData.set(query, response);
      // cache timestamp
      this.fetchCachedSanityDataTimestamp.set(query, Date.now());

      return response;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  public static createResolveRefList = (): {
    list: () => ResolveRefListItem[];
    add: (ref: string, resolve: (data: SanityResolvedDocument) => void) => void;
    resolve: () => Promise<SanityResolvedDocument[]>;
  } => {
    const list: ResolveRefListItem[] = [];

    return {
      list: () => list,
      add: (ref, resolve) => {
        list.push([ref, resolve]);
      },
      resolve: () => SanityService.resolveSanityAssetReferences(list)
    };
  };

  public static resolveSanityAssetReferences = async (
    refs: ResolveRefListItem[]
  ): Promise<SanityResolvedDocument[]> => {
    if (refs.length === 0) return [];

    const query = `*[_id in [${refs.map(([ref]) => `"${ref}"`).join(",")}]]`;

    const data = (await SanityService.fetchSanityData<unknown>(
      query
    )) as SanityResolvedDocument[];

    refs.forEach(([ref, resolve]) => {
      const resolved = data.find((d) => d._id === ref);
      if (resolved) {
        resolve?.(resolved);
      }
    });

    return data;
  };
}
