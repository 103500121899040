import { Cubit } from "blac";

export interface BlockingLoadingOverlayErrorProps {
  title?: string;
  message?: string;
  retry?: () => void;
  onCancel?: () => void;
}

interface BlockingLoadingOverlayState {
  status: "error" | "loading" | "none" | "success";
  error?: BlockingLoadingOverlayErrorProps;
  bg: "solid" | "transparent" | "branded";
  fadeIn: boolean;
}

export class BlockingLoadingOverlayBloc extends Cubit<BlockingLoadingOverlayState> {
  constructor() {
    super({
      status: "none",
      bg: "transparent",
      fadeIn: true
    });
  }

  public readonly endLoading = () => {
    this.clearTimeout();
    this.emit({
      ...this.state,
      status: "none"
    });
  };

  public readonly loadingSuccess = () => {
    this.clearTimeout();
    this.emit({
      ...this.state,
      status: "success"
    });

    setTimeout(() => {
      this.endLoading();
    }, 1400);
    this.clearTimeout();
  };

  public readonly loadingError = (error?: BlockingLoadingOverlayErrorProps) => {
    this.clearTimeout();
    this.emit({
      ...this.state,
      status: "error",
      error
    });
    this.clearTimeout();
  };

  public readonly startLoading = (
    options: {
      bg?: BlockingLoadingOverlayState["bg"];
      timeout?: number;
      fadeIn?: boolean;
    } = {}
  ) => {
    this.emit({
      ...this.state,
      error: undefined,
      status: "loading",
      bg: options.bg ?? "transparent",
      fadeIn: options.fadeIn ?? true
    });
    this.startTimeout(options.timeout);
    return [this.loadingSuccess, this.loadingError];
  };

  timeout: number | undefined = undefined;
  startTimeout = (timeoutTime: number = 30000) => {
    this.clearTimeout();
    if (timeoutTime < 0) return;
    this.timeout = window.setTimeout(() => {
      this.endLoading();
    }, timeoutTime);
  };

  public readonly callErrorRetry = () => {
    if (this.state.error?.retry) {
      this.state.error.retry();
    }
    this.clearTimeout();
  };

  public readonly callOnCancel = () => {
    if (this.state.error?.onCancel) {
      this.state.error.onCancel();
    }
    this.clearTimeout();
  };

  clearTimeout = () => {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  };
}

const BlockingLoadingOverlayController = new BlockingLoadingOverlayBloc();
export default BlockingLoadingOverlayController;
