enum FunnelKey {
  promo = "promo",
  treatmentPlan = "treatment-plan",
  weightLoss = "weight-loss",
  medicare = "medicare",
  test = "test",
  prescryptive = "prescryptive",
  none = "_none_",
  cdc_dpp = "cdcdpp",
  universal = "universal",
  lilly = "lilly",
  smithrx = "smithrx",
  eternalHealth = "eternalhealth",
  kwikTrip = "kwiktrip",
  evernorth = "evernorth",
  wipfli = "wipfli",
  stateOfIndiana = "state-of-indiana",
  transcarent = "transcarent",
  amazon = "amazon"
}

export default FunnelKey;
