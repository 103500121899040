import { Cubit } from "blac";
import type { CameraPreviewOptions } from "@capacitor-community/camera-preview";
import { CameraPreview } from "@capacitor-community/camera-preview";
import translate from "src/lib/translate";
import blockingLoadingOverlayController from "src/ui/components/BlockingLoadingOverlay/BlockingLoadingOverlayController";
import { FilesPicker } from "src/state/FilesCubit/FilesPicker";
import { DebugBlocController } from "src/ui/components/AppDebug/AppDebug";
import reportErrorSentry from "src/lib/reportErrorSentry";

export default class CameraPreviewBloc extends Cubit<{
  show: boolean;
  loaded: boolean;
  title?: string;
  description?: string;
  flashMode?: "off" | "torch";
}> {
  constructor() {
    super({
      show: false,
      loaded: false
    });
  }

  flashSwitchOptions = ["off", "torch"] as const;

  onClose = () => {};

  onCapture = (file: File) => {
    // eslint-disable-next-line no-console
    console.log("Capture", file);
  };

  ratio = 1;

  init = async () => {
    if (this.state.loaded) return;

    const [, loadingFail] = blockingLoadingOverlayController.startLoading();

    try {
      const webWrap = document.getElementById("cameraWrap");
      const frame = document.getElementById("frameBorder");

      if (!frame) throw new Error("frame not found");

      if (webWrap) {
        webWrap.style.setProperty("--preview-ratio", String(1 / this.ratio));
      }

      const { width, left, top } = frame.getBoundingClientRect();
      const height = Math.round(width * this.ratio);

      const options: CameraPreviewOptions = {
        parent: "cameraPreview",
        enableHighResolution: true,
        position: "rear",
        lockAndroidOrientation: true,
        rotateWhenOrientationChanged: false,
        disableAudio: true,
        enableZoom: false,
        enableOpacity: false,
        toBack: false,
        width: Math.round(width),
        height,
        x: Math.round(left),
        y: Math.round(top)
      };

      DebugBlocController.setExtra(options as Record<string, unknown>);

      if (webWrap) {
        webWrap.style.setProperty(
          "--preview-height",
          String(options.height ?? 0) + "px"
        );
        webWrap.style.setProperty(
          "--preview-width",
          String(options.width ?? 0) + "px"
        );
        webWrap.style.setProperty("--preview-x", String(options.x ?? 0) + "px");
        webWrap.style.setProperty("--preview-y", String(options.y ?? 0) + "px");
      }

      await CameraPreview.start(options);

      this.emit({
        ...this.state,
        loaded: true
      });
      blockingLoadingOverlayController.endLoading();
    } catch (e) {
      reportErrorSentry(e);
      this.stop();
      const error = e as Error | undefined;
      if (error?.message === "Permission denied") {
        loadingFail({
          title: translate("error_permissions_denied"),
          message: translate("error_permissions_denied_message")
        });
      } else {
        loadingFail({
          title: translate("error_camera_not_found"),
          message: translate("error_camera_not_found_message")
        });
      }
    }
    void this.detectFlashOptions();
  };

  private readonly detectFlashOptions = async () => {
    try {
      const flashes = await CameraPreview.getSupportedFlashModes();

      if (flashes.result.includes("torch") && flashes.result.includes("off")) {
        this.emit({
          ...this.state,
          flashMode: "off"
        });
      }
    } catch (e) {
      /* empty */
    }
  };

  toggleFlashMode = async () => {
    const currentFlashMode = this.state.flashMode;
    const currentFlashIndex = this.flashSwitchOptions.indexOf(
      currentFlashMode ?? "off"
    );
    const nextFlashIndex =
      (currentFlashIndex + 1) % this.flashSwitchOptions.length;
    const nextFlashMode = this.flashSwitchOptions[nextFlashIndex];

    try {
      await CameraPreview.setFlashMode({ flashMode: nextFlashMode });
      this.emit({
        ...this.state,
        flashMode: nextFlashMode
      });
    } catch (e) {
      /* empty */
    }
  };

  hashChangeHandler = () => {
    if (window.location.hash !== "#cameraPreview") {
      this.stop();
    }
  };

  listenForHashChange = () => {
    window.addEventListener("hashchange", this.hashChangeHandler);
  };

  capture = async () => {
    try {
      const result = await CameraPreview.capture({
        quality: 100,
        width: 2000,
        height: 2000 * this.ratio
      });
      const base64PictureData = result.value;
      const blob = FilesPicker.base64toBlob(base64PictureData, "image/jpeg");
      const fileFromBlob = new File([blob], "image.jpeg", {
        type: "image/jpeg"
      });

      this.onCapture(fileFromBlob);
      this.close();
    } catch (e) {
      reportErrorSentry(e);
    }
  };

  stop = () => {
    this.close();
  };

  open = (options: {
    ratio?: number;
    onClose?: () => void;
    onCapture: (file: File) => void;
    title?: string;
    description?: string;
  }) => {
    const ratioFlipped = 1 / (options.ratio ?? 1);
    this.ratio = options.ratio ? ratioFlipped : this.ratio;
    this.onClose = options.onClose ?? this.onClose;
    this.onCapture = options.onCapture;

    window.location.hash = "#cameraPreview";
    this.listenForHashChange();
    this.emit({
      show: true,
      loaded: false,
      title: options.title,
      description: options.description
    });
  };

  close = () => {
    if (!this.state.show) return;

    void CameraPreview.stop();
    this.onClose();
    window.removeEventListener("hashchange", this.hashChangeHandler);
    this.emit({
      show: false,
      loaded: false
    });
  };
}
