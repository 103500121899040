import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Language, { Languages, Locale } from "src/constants/language";
import { UserPreferenceKeys } from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import { userPreferences } from "src/state/state";
import { featureFlags } from "./featureFlags";
import reportErrorSentry from "./reportErrorSentry";

import translationEnglish from "src/translations/en.json";
import translationSpanish from "src/translations/es.json";

export const getSupportedUserLanguage = (): Language => {
  const sessionOverwrite = sessionStorage.getItem("9am.initialLanguage");
  const userLangList = [
    sessionOverwrite,
    userPreferences.state[UserPreferenceKeys.language],
    ...navigator.languages
  ];

  const userLangShortList = userLangList.map((lang) =>
    lang?.toLowerCase().slice(0, 2)
  );

  const userLangSupported = userLangShortList.find(
    (lang) => Languages.indexOf(lang as Language) !== -1
  ) as Language | undefined;

  return userLangSupported ?? Languages[0];
};

export const getSupportedUserLocale = (): Locale => {
  const userLanguage = getSupportedUserLanguage();
  return Locale[userLanguage] ?? Locale.en;
};

export const initializeLocalisation = async (
  locale: Locale = getSupportedUserLocale(),
  translationSupported = featureFlags.translationActive
): Promise<void> => {
  const lng = translationSupported
    ? ((locale ?? Language.en).slice(0, 2) as Language)
    : Language.en;
  const currentLanguage = i18next.language;

  if (currentLanguage === lng) {
    return;
  }

  if (Languages.indexOf(lng) === -1) {
    throw new Error(`Language "${locale}" not supported`);
  }

  window.nineConfig = {
    ...window.nineConfig,
    language: lng
  };

  await i18next.use(initReactI18next).init({
    resources: {
      en: {
        translation: translationEnglish
      },
      es: {
        translation: translationSpanish
      }
    },
    lng,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });
};

export const changeLanguage = async (locale: Locale | string) => {
  const sessionOverwrite = sessionStorage.getItem("9am.initialLanguage");
  const isString = typeof locale === "string";
  const language = sessionOverwrite ?? (isString ? locale.slice(0, 2) : "en");
  const lng = Languages.indexOf(language) !== -1 ? language : Languages[0];
  window.nineConfig = {
    ...window.nineConfig,
    language: lng
  };
  await i18next.changeLanguage(lng, (err) => {
    if (err) reportErrorSentry(err);
  });
};
