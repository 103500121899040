import { FileControllerService, OpenAPI } from "@9amhealth/openapi";
import { FileOpener } from "@capacitor-community/file-opener";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Cubit } from "blac";
import { nanoid } from "nanoid";
import appErrors from "src/constants/appErrors";
import type FileType from "src/constants/fileType";
import { globalEvents } from "src/constants/globalEvents";
import { DateFormats, dateLocal } from "src/lib/date";
import envVariables from "src/lib/envVariables";
import imageLoadPromise from "src/lib/imageLoadPromise";
import mimeType from "src/lib/mimeType";
import { isHybridApp } from "src/lib/platform";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { toast } from "src/state/state";

interface FileAttributes {
  "file.name"?: string;
  "file.%s.name"?: string;
  "image.width"?: number;
  "image.height"?: number;
  "subscription.identity.face"?: boolean;
  "subscription.identity.id"?: boolean;
  "labs.user_result"?: boolean;
  "file.tags"?: string[];
  "file.lastModified"?: string;
  "file.size"?: number;
  "file.type"?: FileType;
  "source.name"?: string;
}

export interface FileItem {
  id: string;
  attributes: FileAttributes;
  type: string;
  blob?: Blob;
  path?: string;
  size?: number;
  error?: string;
  local?: boolean;
}

export default class FilesCubit extends Cubit<FileItem[]> {
  private onAddCallbacks: Record<
    string,
    ((file: FileItem) => void) | undefined
  > = {};

  private readonly loadingFiles = new Map<string, boolean>();

  constructor() {
    super([]);

    window.addEventListener(globalEvents.USER_CLEAR, () => {
      this.emit([]);
    });
  }

  public readonly getFileById = (id: string): FileItem | undefined => {
    return this.state.find((item) => item.id === id);
  };

  public readonly waitForFile = async (id: string): Promise<FileItem> => {
    return new Promise((resolve) => {
      const index = nanoid(5);
      this.onAddCallbacks[index] = (file): void => {
        if (file.id === id) {
          resolve(file);
          this.onAddCallbacks[index] = undefined;
        }
      };
    });
  };

  public readonly getFileByAttribute = (
    attribute: FileAttributes
  ): FileItem[] => {
    return this.state.filter((item) => {
      const attributeName = Object.keys(attribute)[0] as never;
      return (
        Object.keys(item.attributes).includes(attributeName) &&
        item.attributes[attributeName] === attribute[attributeName]
      );
    });
  };

  public readonly uploadFile = async (options: {
    file: File;
    fileAttributes?: FileAttributes;
  }): Promise<FileItem | undefined> => {
    try {
      const { file, fileAttributes } = options;

      const attributes: FileAttributes = {
        "source.name": envVariables.APP_ENV
      };
      attributes["file.name"] = file.name;
      attributes["file.lastModified"] = dateLocal(file.lastModified).format(
        DateFormats.ISO_FULL
      );
      attributes["file.size"] = file.size;
      const filePath = URL.createObjectURL(file);

      if (mimeType.isImage(file.type)) {
        const img = await imageLoadPromise(filePath);

        attributes["image.width"] = img.width;
        attributes["image.height"] = img.height;
      }

      const allAttributes = {
        ...attributes,
        ...fileAttributes
      };

      const response = await FileControllerService.createFile(
        file,
        JSON.stringify(allAttributes)
      );

      const item: FileItem = {
        id: response.data.fileId,
        attributes: allAttributes,
        local: true,
        type: file.type,
        path: filePath
      };

      this.emit([...this.state, item]);
      return item;
    } catch (e: unknown) {
      toast.error("error_upload_file_failed");
      reportErrorSentry(e);
    }
  };

  static requestDownloadFile = async (id: string): Promise<Response> =>
    fetch(`${envVariables.API_BASE_URL}/v1/files/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${OpenAPI.TOKEN as string}`
      }
    });

  static requestDownloadFilePreview = async (
    id: string,
    w: number,
    h: number
  ): Promise<Response> =>
    fetch(`${envVariables.API_BASE_URL}/v1/files/${id}/preview/${w}/${h}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${OpenAPI.TOKEN as string}`
      }
    });

  public readonly loadFile = async (options: {
    id?: string;
    save?: boolean;
  }): Promise<FileItem | undefined> => {
    if (!options.id) {
      return;
    }

    const fileCached = this.getFileById(options.id);
    if (!options.save && fileCached && !fileCached.local) {
      return fileCached;
    }

    if (options.save && fileCached?.blob) {
      void FilesCubit.saveFile(fileCached);
      return;
    }

    const file: FileItem = {
      id: options.id,
      attributes: {},
      type: ""
    };

    if (this.loadingFiles.has(file.id)) {
      return;
    }

    this.loadingFiles.set(file.id, true);

    try {
      const response = await FilesCubit.requestDownloadFile(file.id);

      const blob = await response.blob();

      file.attributes = JSON.parse(
        response.headers.get("X-NineamHealth-File-Attributes") ?? "{}"
      ) as FileAttributes;

      file.blob = blob;
      file.path = URL.createObjectURL(file.blob);

      const removeLocal = this.state.filter((item) => {
        return !(item.id === file.id && item.local);
      });
      this.emit([...removeLocal, file]);

      if (options.save) {
        void FilesCubit.saveFile(file);
      }

      this.addNotify(file);

      return file;
    } catch (e: unknown) {
      reportErrorSentry(e);
      toast.error("download.failed");
      const errorFile = { ...file, error: appErrors.generic };
      this.emit([...this.state, errorFile]);
    } finally {
      this.loadingFiles.delete(file.id);
    }
  };

  public readonly addFile = (id: string, fileBlob: Blob): FileItem => {
    const file: FileItem = {
      id,
      attributes: {},
      type: ""
    };

    try {
      file.path = URL.createObjectURL(fileBlob);
      this.emit([...this.state, file]);
      this.addNotify(file);
    } catch (e: unknown) {
      // eslint-disable-next-line no-console
      console.error(e);
      return { ...file, error: appErrors.generic };
    }

    return file;
  };

  static saveFile = async (file?: FileItem): Promise<void> => {
    if (!file?.path) {
      reportErrorSentry("Attempted to download a file that is not defined");
      return;
    }

    let name = file.attributes["file.name"];

    if (!name) {
      for (const key of Object.keys(file.attributes)) {
        const keyValue = file.attributes[key as keyof FileAttributes];
        if (key.endsWith(".name") && typeof keyValue === "string") {
          name = keyValue;
        }
      }
    }

    const { type } = file;

    if (name) {
      name = `9amHealth_${name}`;
    }

    if (!name) {
      toast.error("error.downloadFile");
      reportErrorSentry(new Error("No name to save"), {
        attributes: file.attributes,
        type
      });
      return;
    }

    if (isHybridApp()) {
      await FilesCubit.saveFileNative(name, file);
    } else {
      await FilesCubit.saveFileWeb(name, file);
    }
  };

  static blobToBase64 = async (blob: Blob): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = (): void => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  };

  static saveFileNative = async (
    name: string,
    file: FileItem
  ): Promise<void> => {
    try {
      const { blob } = file;
      const blobAsBase64 = blob
        ? await FilesCubit.blobToBase64(blob)
        : undefined;

      if (!blobAsBase64) {
        reportErrorSentry(new Error("Failed to convert blob to base64"), {
          attributes: file.attributes,
          type: file.type,
          path: file.path
        });
        return;
      }

      // remove any characters that could cause issues for the path
      let nativeFriendFileName = name;
      nativeFriendFileName = nativeFriendFileName.replace(/[^a-zA-Z0-9]/g, "-");

      const writeFileResult = await Filesystem.writeFile({
        path: nativeFriendFileName,
        data: blobAsBase64,
        directory: Directory.Cache
      });

      await FilesCubit.openFile(writeFileResult.uri, blob?.type);
    } catch (error) {
      reportErrorSentry(error);
    }
  };

  static saveFileWeb = async (name: string, file: FileItem): Promise<void> => {
    if (!file.path) {
      reportErrorSentry(new Error("No path to save"), {
        attributes: file.attributes,
        type: file.type,
        path: file.path
      });
      return;
    }

    const link = document.createElement("a");
    link.download = name;
    link.href = file.path;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  static openFile = async (file: string, type?: string): Promise<void> => {
    if (!isHybridApp()) {
      reportErrorSentry(
        new Error("Attempted to open a file on a non-native platform")
      );
      return;
    }

    await FileOpener.open({
      filePath: file,
      contentType: type,
      openWithDefault: true
    });
  };

  public readonly removeFile = (id: string): void => {
    this.emit(this.state.filter((file) => id !== file.id));
  };

  private readonly addNotify = (file: FileItem): void => {
    for (const id in this.onAddCallbacks) {
      this.onAddCallbacks[id]?.(file);
    }
  };

  previewUrlCache: Record<string, string | undefined> = {};
  previewRequestCache: Record<string, Promise<string> | undefined> = {};
  public readonly loadPreview = async (
    id: string,
    w = 400,
    h = 400
  ): Promise<string> => {
    const requestId = `${id}-${w}-${h}`;

    const request = async (): Promise<string> => {
      try {
        const response = await FilesCubit.requestDownloadFilePreview(id, w, h);
        if (!response.ok) {
          throw new Error(`Failed to load preview for file ${id}`);
        }
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        this.previewUrlCache[requestId] = url;
        return url;
      } catch (e: unknown) {
        reportErrorSentry(e);
      }
      return "";
    };

    if (this.previewUrlCache[requestId]) {
      return this.previewUrlCache[requestId] ?? "";
    }

    if (this.previewRequestCache[requestId]) {
      return this.previewRequestCache[requestId] ?? "";
    }

    this.previewRequestCache[requestId] = request();
    return this.previewRequestCache[requestId] ?? "";
  };
}
