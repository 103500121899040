import type { CSSProperties, FC, ReactNode, SyntheticEvent } from "react";
import React from "react";
import { Link } from "react-router-dom";
import { IconCaretToRightSmallArrow } from "src/constants/icons";
import styled from "@emotion/styled";
import type { LabColor } from "src/constants/LabColor";

const StyledLink = styled(Link)`
  border-radius: inherit;
  transition: background-color 0.1s ease-in-out;
  color: inherit;
  text-decoration: inherit;

  &[aria-disabled="true"] {
    pointer-events: none;

    .icon,
    .label,
    .arrow {
      opacity: 0.2;
    }
  }

  body &:active {
    background-color: #0000000a;
  }
  body &:hover {
    text-decoration: none;
  }
  .custom {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.8rem;
    svg {
      transform: scale(1.4);
    }
  }

  //  all items in the StyleLink are styled in "Collection.ts"
`;

const CollectionItemLink: FC<{
  icon?: ReactNode;
  label?: ReactNode;
  to?: string;
  onClick?: () => void;
  iconSize?: string;
  disabled?: boolean;
  action?: ReactNode;
  status?: ReactNode;
  detail?: ReactNode;
  color?: LabColor | string;
  size?: "large";
  endIcon?: ReactNode;
}> = (props) => {
  const { icon, label, endIcon, to, iconSize, onClick, disabled, action } =
    props;
  const handleClick = (e: SyntheticEvent) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <StyledLink
      to={to ?? "#"}
      onClick={handleClick}
      className="link"
      aria-disabled={disabled ? "true" : "false"}
      style={
        {
          "--icon-size": iconSize,
          "--icon-bg": props.color
        } as CSSProperties
      }
    >
      {icon && (
        <div className="icon" data-size={props.size}>
          {icon}
        </div>
      )}
      <div className="label">
        {label}
        {props.status && <div className="status">{props.status}</div>}
      </div>
      {props.detail && <div className="detail">{props.detail}</div>}
      {(to || onClick) && !action && (
        <div className={endIcon ? "custom" : "arrow"}>
          {endIcon ?? <IconCaretToRightSmallArrow />}
        </div>
      )}
      {action && <div className="action">{action}</div>}
    </StyledLink>
  );
};
export default CollectionItemLink;
