import type {
  CachedObject,
  QuestionnaireLogic
} from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import type {
  QuestionnaireEndScreen,
  QuestionnaireField
} from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import type { TranslationKey } from "src/types/translationKey";

export enum TimeCode {
  VOID = "VOID",
  MORN = "MORN",
  MORN_EARLY = "MORN.early",
  MORN_LATE = "MORN.late",
  NOON = "NOON",
  AFT = "AFT",
  AFT_EARLY = "AFT.early",
  AFT_LATE = "AFT.late",
  EVE = "EVE",
  EVE_EARLY = "EVE.early",
  EVE_LATE = "EVE.late",
  NIGHT = "NIGHT",
  PHS = "PHS",
  HS = "HS",
  WAKE = "WAKE",
  C = "C",
  CM = "CM",
  CD = "CD",
  CV = "CV",
  AC = "AC",
  ACM = "ACM",
  ACD = "ACD",
  ACV = "ACV",
  PC = "PC",
  PCM = "PCM",
  PCD = "PCD",
  PCV = "PCV"
}

export const TIME_CODE_TEXT: { [key in TimeCode]: TranslationKey } = {
  [TimeCode.VOID]: "" as TranslationKey,
  [TimeCode.MORN]: "TimeCode.MORN",
  [TimeCode.MORN_EARLY]: "TimeCode.MORN_EARLY",
  [TimeCode.MORN_LATE]: "TimeCode.MORN_LATE",
  [TimeCode.NOON]: "TimeCode.NOON",
  [TimeCode.AFT]: "TimeCode.AFT",
  [TimeCode.AFT_EARLY]: "TimeCode.AFT_EARLY",
  [TimeCode.AFT_LATE]: "TimeCode.AFT_LATE",
  [TimeCode.EVE]: "TimeCode.EVE",
  [TimeCode.EVE_EARLY]: "TimeCode.EVE_EARLY",
  [TimeCode.EVE_LATE]: "TimeCode.EVE_LATE",
  [TimeCode.NIGHT]: "TimeCode.NIGHT",
  [TimeCode.PHS]: "TimeCode.PHS",
  [TimeCode.HS]: "TimeCode.HS",
  [TimeCode.WAKE]: "TimeCode.WAKE",
  [TimeCode.C]: "TimeCode.C",
  [TimeCode.CM]: "TimeCode.CM",
  [TimeCode.CD]: "TimeCode.CD",
  [TimeCode.CV]: "TimeCode.CV",
  [TimeCode.AC]: "TimeCode.AC",
  [TimeCode.ACM]: "TimeCode.ACM",
  [TimeCode.ACD]: "TimeCode.ACD",
  [TimeCode.ACV]: "TimeCode.ACV",
  [TimeCode.PC]: "TimeCode.PC",
  [TimeCode.PCM]: "TimeCode.PCM",
  [TimeCode.PCD]: "TimeCode.PCD",
  [TimeCode.PCV]: "TimeCode.PCV"
};

export type MedicalInputData = Record<Partial<TimeCode>, number | undefined>;
export type MultipleTextInputData = Record<string, string>;

export enum CustomQuestionnaireSchema {
  TYPEFORM = "Ap3c7zxY"
}

export interface CustomQuestionnaireChoice {
  choiceId: string;
  value: boolean | number | string;
}

export type CustomQuestionnaireAnswerType =
  | "choice"
  | "custom-object"
  | "multiple-choice"
  | "text";

export type CustomQuestionnaireAnswerValue =
  | CustomQuestionnaireChoice
  | CustomQuestionnaireChoice[]
  | Record<string, number | string>
  | boolean
  | number
  | string;

export interface CustomQuestionnaireAnswer {
  questionId: string;
  fieldType: CustomQuestionnaireAnswerType | string;
  fieldValue: CustomQuestionnaireAnswerValue;
}

export interface CustomQuestionnaireFilterAnswerOptions {
  questionId: string;
  includeOptions: string[];
}

export interface CustomQuestionnaireResult {
  questionnaireRef: {
    type: string | "TYPEFORM";
    id: string;
  };
  answers: {
    json: CustomQuestionnaireAnswer[];
    schemaId: CustomQuestionnaireSchema.TYPEFORM;
  };
}

export interface CustomQuestionnaireFormMeta extends AnyObject {
  title?: string;
}

export interface GlobalConfigs {
  disableDataPrefill?: boolean;
}

export interface QuestionnaireState {
  formId: string;
  hiddenFields: CachedObject;
  fields: QuestionnaireField[];
  error: string;
  endScreens: QuestionnaireEndScreen[];
  logic: QuestionnaireLogic[];
  formMeta: CustomQuestionnaireFormMeta;
  customFormData: CachedObject;
  logicSteps: string[];
  globalConfigs?: GlobalConfigs;
}
