import { CustomFieldPropertyRegex } from "src/state/QuestionnaireStepCubit/CustomFields";
import type { QuestionnaireSelectChoice } from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import type { TranslationKey } from "src/types/translationKey";
import type { QuestionnaireParsedSelectChoice } from "src/ui/components/MultipleChoiceInput/MultipleChoiceInput";

const parseChoice = (
  choices: QuestionnaireSelectChoice[] | undefined,
  simpleParse = false
): QuestionnaireParsedSelectChoice[] => {
  return (
    choices?.map((choice) => {
      const autoselectOptionLabelRegex = /\[autoselect-if="(.*)"\]/;
      let affix;
      let clearOther = false;
      let { label } = choice;
      let groups: string[] | undefined = undefined;

      const autoselectIfKey = autoselectOptionLabelRegex.exec(label)?.[1];

      if (simpleParse) {
        return {
          ...choice,
          label,
          affix,
          clearOther,
          groups,
          autoselectIfKey
        };
      }

      const affixRegex = CustomFieldPropertyRegex.AFFIX_SUFFIX;
      const clearRegex = CustomFieldPropertyRegex.CLEAR_OTHER;
      const groupRegex = CustomFieldPropertyRegex.GROUP_COMMENT;

      label = label.replace(affixRegex, (match, type, key: string): string => {
        affix = key;
        return "";
      }) as TranslationKey;

      label = label.replace(clearRegex, (match, type, key: string): string => {
        if (key) clearOther = true;
        return "";
      }) as TranslationKey;

      label = label.replace(groupRegex, (match): string => {
        groups = match
          .replace(/[{}]/g, "")
          .split(",")
          .map((group) => group.trim());
        return "";
      }) as TranslationKey;

      label = label.trim() as TranslationKey;

      return {
        ...choice,
        label,
        affix,
        clearOther,
        groups,
        autoselectIfKey
      } satisfies QuestionnaireParsedSelectChoice;
    }) ?? []
  );
};

export default parseChoice;
