import type { FC } from "react";
import React from "react";
import translate from "src/lib/translate";
import QuestionnaireCubit, {
  UserSelectedDiagnosis
} from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import { useBloc } from "src/state/state";
import Translate from "src/ui/components/Translate/Translate";

const ListUserSelectedConditions: FC = () => {
  const [, { loadSelectedValuesOnly, loadSelectedDiagnosis }] = useBloc(
    QuestionnaireCubit,
    {
      create: () => new QuestionnaireCubit("", { disableTracking: true })
    }
  );
  const [conditions, setConditions] = React.useState<UserSelectedDiagnosis[]>(
    []
  );

  React.useEffect(() => {
    void loadSelectedValuesOnly(loadSelectedDiagnosis).then((values) => {
      setConditions(values as UserSelectedDiagnosis[]);
    });
  }, []);

  if (conditions.length === 1 && conditions[0] === UserSelectedDiagnosis.NONE) {
    return (
      <div>
        <Translate msg="program_designed_for_you_no_conditions" />
      </div>
    );
  }

  const t_condition = (key: string) =>
    translate(`user_selected_diagnosis_condition`, {
      context: key
    });

  // join conditions with "," and last with "and", use the translation keys `user_selected_diagnosis_condition_{{condition}}` to get the condition name.
  const conditionString = conditions
    .map((condition, index) => {
      if (index === conditions.length - 1) {
        return t_condition(condition);
      } else if (index === conditions.length - 2) {
        return t_condition(condition) + ` ${translate("text_generic_and")} `;
      } else {
        return t_condition(condition) + ", ";
      }
    })
    .join("");

  return (
    <span>
      <Translate
        msg="program_designed_for_you"
        variables={{
          conditions: conditionString
        }}
      />
    </span>
  );
};

export default ListUserSelectedConditions;
