import styled from "@emotion/styled";
import type { PropsWithChildren, ReactElement } from "react";
import React, { useEffect, useMemo } from "react";

import { signupHeaderHeightMobile } from "src/constants/layout";
import parsePriceText from "src/lib/parsePriceText";
import translate from "src/lib/translate";
import type { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import LoadingCubit from "src/state/LoadingCubit/LoadingCubit";
import SignupCubit from "src/state/SignupCubit/SignupCubit";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import { useBloc } from "src/state/state";
import type { TranslationKey } from "src/types/translationKey";
import Loader from "src/ui/components/Loader/Loader";
import Translate from "src/ui/components/Translate/Translate";

export interface SignupWrapperProps {
  onContinue?: () => Promise<unknown> | unknown;
  onBack?: () => void;
  buttonLabel?: TranslationKey;
  loadingKey?: LoadingKey;
  style?: React.CSSProperties;
  hideBottomNavbar?: boolean;
  hideTotal?: boolean;
  transparentNav?: boolean;
}

export const PriceBox = styled.div`
  label: PriceBox;
  display: flex;
  flex-direction: column;
  font-size: 17px;
  justify-content: stretch;
  font-weight: 700;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
`;

export const Discount = styled.div<{ crossedOut?: boolean }>`
  color: var(--color-sunrise-blue);
  text-decoration: ${({ crossedOut }): string =>
    crossedOut ? "line-through" : ""};
`;

export const LineThroughLabel = styled.span`
  color: var(--color-error);
`;

const Wrapper = styled.div`
  label: SignupWrapper;
  width: min(100%);
  margin: 0 auto;
  /* padding-bottom: calc(91px + 2em); */
  min-height: calc(
    100vh - calc(${signupHeaderHeightMobile}px + 3em) - var(
        --ion-safe-area-bottom,
        0
      ) - var(--ion-safe-area-top, 0)
  );
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding-top: calc(var(--ion-safe-area-top, 0) + 2em); */
  transition: padding 0.3s;
  padding-bottom: calc(var(--keyboard-height, 0) + 2em);

  .wrap-center & {
    justify-content: center;
  }
`;

const Content = styled.div`
  width: min(var(--page-max-width-narrow), 100%);
  margin: 0 auto 4em;
`;

export default function SignupWrapper({
  onContinue,
  onBack,
  children,
  hideBottomNavbar,
  hideTotal,
  loadingKey,
  buttonLabel,
  transparentNav
}: PropsWithChildren<SignupWrapperProps>): ReactElement {
  const [{ showBackButton }, { setBackButton }] = useBloc(SignupCubit);
  const [subscriptionState, { getFullSubscription, getFinalPriceMonthly }] =
    useBloc(SubscriptionCubit);
  const sub = useMemo(getFullSubscription, [
    subscriptionState.allSubscriptions
  ]);
  const appliedCoupon = useMemo(() => sub?.appliedCoupons[0], [sub]);
  const finalPrice = getFinalPriceMonthly(sub?.id);

  const [allLoading, loader] = useBloc(LoadingCubit);
  const isLoading = useMemo(() => {
    return allLoading.length > 0;
  }, [allLoading, loader]);

  useEffect(() => {
    if (showBackButton !== Boolean(onBack)) {
      setBackButton(Boolean(onBack));
    }
  }, [onBack]);

  const priceText = parsePriceText({
    sub,
    appliedCoupon,
    finalPrice
  });

  useEffect(() => {
    if (!hideBottomNavbar) {
      // add class to body
      document.body.classList.add("moveZe");
    } else {
      // remove class from body
      document.body.classList.remove("moveZe");
    }

    return () => {
      document.body.classList.remove("moveZe");
    };
  }, [hideBottomNavbar]);

  return (
    <Wrapper>
      <Loader fixed grow loadingKey={loadingKey}>
        <Content>{children}</Content>
      </Loader>

      {!hideBottomNavbar && (
        <nine-bottom-navbar
          style={{
            margin: "0",
            position: "relative",
            background: transparentNav ? "transparent" : "var(--color-white)"
          }}
        >
          <h5 slot="content" className="m0">
            {!hideTotal && (
              <>
                <strong
                  className="color-c-60"
                  style={{ marginRight: `calc(var(--space-sm) * 0.14)` }}
                >
                  <Translate msg="total" />:
                </strong>
                <strong>{priceText}</strong>
                <p className="m0 inline">
                  {translate("signup.price", { price: "" })}
                </p>
              </>
            )}
          </h5>
          <button
            className="inline-button"
            tabIndex={-1}
            onClick={onContinue}
            slot="button"
          >
            <nine-button
              color="sunrise-light"
              disabled={isLoading ? "true" : "false"}
            >
              {buttonLabel && translate(buttonLabel)}
              {!buttonLabel && translate("continue")}
            </nine-button>
          </button>
        </nine-bottom-navbar>
      )}
    </Wrapper>
  );
}
