import type { ReactElement } from "react";
import React, { useEffect } from "react";
import { IonApp, setupIonicReact } from "@ionic/react";
import "src/state/state";
import CustomSuspense from "src/ui/components/CustomSuspense/CustomSuspense";
import MainRouter from "src/ui/components/MainRouter/MainRouter";
import CustomErrorBoundary from "src/ui/components/SentryBoundary/CustomErrorBoundary";
import Toast from "src/ui/components/Toast/Toast";
import AppDebug from "src/ui/components/AppDebug/AppDebug";
import {
  getSupportedUserLocale,
  initializeLocalisation
} from "src/lib/i18next";
import { appVersionState } from "src/state/state";
import { I18nProvider } from "react-aria";

setupIonicReact({});
void initializeLocalisation();

const Async = {
  CameraPreviewFrame: React.lazy(
    async () =>
      import("src/ui/components/CameraPreviewFrame/CameraPreviewFrame")
  ),
  AsyncExtras: React.lazy(
    async () => import("src/ui/components/App/AppAsyncExtras")
  )
};

function App(): ReactElement {
  useEffect(() => {
    void appVersionState.checkForUpdate();
  }, []);
  return (
    <CustomErrorBoundary>
      <IonApp>
        <I18nProvider locale={getSupportedUserLocale()}>
          <nine-helper></nine-helper>
          <Toast />
          <CustomSuspense>
            <Async.AsyncExtras />
          </CustomSuspense>
          <MainRouter />
          <CustomSuspense>
            <Async.CameraPreviewFrame />
          </CustomSuspense>
          <CustomSuspense>
            <div id="actionButtonTarget" />
          </CustomSuspense>
        </I18nProvider>
      </IonApp>
      <AppDebug />
    </CustomErrorBoundary>
  );
}

export default App;
