export const SKU_SEPARATOR = "#";
export const PHONE_COUNTRY_CODE = "+1";
export const ABBREVIATED_QUESTIONNAIRE_ID = "NmrJbFQH";
export const MEDICAL_QUESTIONNAIRE_ID = "Q48WHDmc";
export const MEDICAL_ASSESSMENT_ID = "xhuRrhH9";
export const MEDICARE_MINIMAL_SIGNUP_QUESTIONNAIRE_ID = "HVDP5vGD";
export const MEDICARE_MEDICAL_DETAILS_QUESTIONNAIRE_ID = "FFWgfr0v";
export const SIGNUP_QUESTIONNAIRE_GLP1 = "D6RkD6oD";
export const CDCDPP_ELIGIBILITY_QUESTIONNAIRE_ID = "h7S07G4Y";
export const PLACEHOLDER_QUESTIONNAIRE = ABBREVIATED_QUESTIONNAIRE_ID;
export const FEEDBACK_GOOD_QUESTIONNAIRE_ID = "RbfVQcyi";
export const FEEDBACK_BAD_QUESTIONNAIRE_ID = "fpmAyZSJ";
export const RISK_QUIZ_QUESTIONNAIRE_ID = "UdChB1PZ";

export const FUNNEL_QUESTIONNAIRE_ELIGIBILITY = "J3TCSVXD";
export const FUNNEL_QUESTIONNAIRE_MEDICAL = "wRvs6BNP";

export const FUNNELS_NO_CACHE_QUESTIONNAIRES = ["a37DZc4j"];

export const METADATA_CONTENT_VALUE = "content-value:generated";
export const METADATA_MESSAGE_TYPE = "message:type";
export const METADATA_ORIGINAL_SOURCE = "message:original-source";

export const MEDICAL_QUESTIONNAIRE_ETHNICITY_QUESTION_ID = "gwzs0XuJHrfO";
export const FEEDBACK_GOOD_QUESTIONNAIRE_RATING_QUESTION_ID = "IO6j6rqY1RZe";
export const FEEDBACK_GOOD_QUESTIONNAIRE_RATING_TEXT_QUESTION_ID =
  "jbcJT8E3mSDu";
export const FEEDBACK_BAD_QUESTIONNAIRE_RATING_TEXT_QUESTION_ID =
  "kOo6PZPaJrP4";

export const PHLEBOTOMY_SKU = "AT_HOME_PHLEBOTOMY";
export const BLOOD_SPOT_SKU = "DRY_BLOOD_SPOT_LAB_TEST";
export const MEDICARE_PROGRAM_SKU = "care:medicare";
export const WEIGHT_LOSS_PROGRAM_SKU = "care:weightloss";
export const PRESCRYPTIVE_PROGRAM_ITEM_SKU = "care:pbm:prescryptive";
export const ETERNALHEALTH_PROGRAM_ITEM_SKU = "care:eternalhealth";
export const EVERNORTH_PROGRAM_ITEM_SKU = "care:evernorth";
export const KWIKTRIP_PROGRAM_ITEM_SKU = "care:kwiktrip";
export const WIPFLI_PROGRAM_ITEM_SKU = "care:wipfli";
export const CDCDPP_PROGRAM_ITEM_SKU = "care:cdcdpp";
export const AMAZON_PROGRAM_ITEM_SKU = "care:amazon";

export const BASE_MEMBERSHIP_SKU = "base_membership";
export const ONBOARDING_FEE_SKU = "care:onboarding";

export const MONTHLY_PLAN_SKU = "MONTHLY_PLAN";
export const MONTHLY_PLAN_STRIPES_ONLY_SKU = "MONTHLY_PLAN_STRIPES_ONLY";
export const MONTHLY_PLAN_STRIPES_INCLUDED_SKU = "MONTHLY_PLAN_STRIPES";

export const QUARTERLY_PLAN_SKU = "QUARTERLY_PLAN";
export const QUARTERLY_PLAN_STRIPES_ONLY_SKU = "QUARTERLY_PLAN_STRIPES_ONLY";

export const AVERAGE_DELIVERY_LENGTH_IN_DAYS = 4;

export const METADATA_NEW_MEDS_KEY = "medication.new-med-for-patient";
export const METADATA_REPLACEMENT_MEDS_KEY = "medication.replacement-med";
export const METADATA_DAILY_DOSAGE = "medication.daily-dosage-details";
export const METADATA_DAILY_DOSAGE_TIMES = "medication.daily-dosage-times";
export const METADATA_RAMPING_UP_DOSAGE = "medication.ramping-up-dosage";
export const METADATA_CATEGORY = "medication.category";

export const METADATA_NPS_FEEDBACK_RATING = "nps-feedback:rating";
export const METADATA_NPS_FEEDBACK_RATING_NUMBER = "nps-feedback:ratingNumber";
export const METADATA_NPS_FEEDBACK_RATING_TEXT = "nps-feedback:ratingText";
export const METADATA_NPS_FEEDBACK_SOURCE_ID = "nps-feedback:sourceId";

export const ANDROID_APP_DOWNLOAD_LINK =
  "https://play.google.com/store/apps/details?id=com.join9am.app.android";
export const IOS_APP_DOWNLOAD_LINK =
  "https://apps.apple.com/us/app/9am-at-home-diabetes-clinic/id6443864010";
