import type { GetSuggestedTreatmentPlanResponse } from "@9amhealth/openapi";
import { TreatmentPlanControllerService } from "@9amhealth/openapi";
import { Cubit } from "blac";
import { FeatureFlagName, featureFlags } from "src/lib/featureFlags";
import reportErrorSentry from "src/lib/reportErrorSentry";

interface FetchLogEntry {
  time: number;
  type: "api" | "cache";
}
export default class ApiMiddleware extends Cubit<null> {
  constructor() {
    super(null);
  }

  log = (message: string): void => {
    if (featureFlags.getFlag(FeatureFlagName.loggingApiCache)) {
      // eslint-disable-next-line no-console
      console.info(`[API]: ${message}`);
    }
  };

  lastFetched: Record<string, { time: number; pathname: string } | undefined> =
    {};
  fetchCache: Record<string, Promise<unknown> | undefined> = {};
  fetchCacheLog: Record<string, FetchLogEntry[] | undefined> = {};

  logEvent = (key: string, type: "api" | "cache"): void => {
    const log = this.fetchCacheLog[key] ?? [];
    log.push({ time: Date.now(), type });
    this.fetchCacheLog[key] = log;

    // keep log length under 100
    if (log.length > 100) {
      this.fetchCacheLog[key] = log.slice(log.length - 100);
    }
  };

  checkForIssues = (key: string): void => {
    const log = this.fetchCacheLog[key] ?? [];
    const requestsPreSecondLimit = 1;
    const monitorTimeSeconds = 4;

    // count request in last 4 seconds (4 * 1 request per second)
    const requestsPerSecond = log.filter(
      (entry) => Date.now() - entry.time < monitorTimeSeconds * 1000
    ).length;

    if (requestsPerSecond / monitorTimeSeconds > requestsPreSecondLimit) {
      const issue = `[API].cache: exceeded requests per second limit`;
      // eslint-disable-next-line no-console
      console.error(issue, {
        key,
        requestsPerSecond
      });

      reportErrorSentry(new Error(`${issue} ${key} ${requestsPerSecond}`));

      // clear cache
      this.fetchCache[key] = undefined;
      this.lastFetched[key] = undefined;

      // clear log
      this.fetchCacheLog[key] = undefined;
    }
  };

  cached = async <T>(fetch: () => Promise<T>, timeout = 10_000): Promise<T> => {
    const key = fetch.toString();
    const lastFetch = this.lastFetched[key] ?? { time: 0, pathname: "" };
    const fetchCache = this.fetchCache[key] ?? undefined;

    this.checkForIssues(key);

    const expired = Date.now() - lastFetch.time > timeout;
    const pathnameMatch = window.location.pathname === lastFetch.pathname;

    if (fetchCache && !expired && pathnameMatch) {
      this.log(`using cached API call ${key}`);
      return fetchCache as Promise<T>;
    }

    const promise = fetch();
    this.fetchCache[key] = Promise.resolve(promise);

    this.lastFetched[key] = {
      time: Date.now(),
      pathname: window.location.pathname
    };
    this.log(`fetched and cached API call ${key}`);
    this.logEvent(key, "api");
    return promise;
  };

  clearAll = (): void => {
    this.log(`clearing all cached API calls`);
    this.fetchCache = {};
    this.lastFetched = {};
    this.fetchCacheLog = {};
  };

  cachedSuggestTreatmentPlan =
    async (): Promise<GetSuggestedTreatmentPlanResponse> =>
      this.cached(
        async () =>
          (await TreatmentPlanControllerService.suggestTreatmentPlan()).data
      );
}
